
import React, { Component, useEffect, useState } from 'react';
import { useParams } from "react-router";
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton,
          IonButtons

} from '@ionic/react';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import {courtli, courtliHealthCheckWithRedirect, changeUserFieldAlsoExtraData, initOnboarding, getGenderDisplay, getUserFieldDisplayValue,
    getOnboardingNextStep,
    getUser,
    goToUrlAndReload} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack } from 'ionicons/icons';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
    history: any;
    user: any;
    onClose: () => void;
};

const GenderWizard: React.FC<Props> = ({ history, user, onClose }) => {
  
    
    /// VARIABLES
    let first_load:boolean = true;

    let [nextStep, setNextStep] = useState<any>({
        title: "START NOW",
        wizard: "/tabs/profile"
    });
    let params = useParams<{ field_to_edit: string }>();
    let field_to_edit = params.field_to_edit;
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {

        // console.log("GenderWizard useEffect()");
        courtliHealthCheckWithRedirect(history);
        // initOnboarding(setNextStep);
        
    }, [])

    async function changeGenderAndRedirect(gender:string){
        // let response:any = await changeGender(gender);
        
        // console.log("changeGenderAndRedirect() field_to_edit", field_to_edit);
        let response:any = await changeUserFieldAlsoExtraData(field_to_edit, gender);
        // console.log("changeGenderAndRedirect() response", response);

        /// REDIRECT
        // console.log("Going back to where the wizard was called from");
        
        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
        let next_step = getOnboardingNextStep(await getUser({use_cache: true}));
        goToUrlAndReload(history, next_step.wizard);
        // history.push(next_step.wizard);
        
    }
    return (
      <IonPage className="backround_almost_white">
        <IonHeader className="no-border">
            <IonToolbar className="user-profile-toolbar toolbar-no-border ">
            <IonButtons slot="start">
                <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
            </IonButtons>
            {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
            <IonButtons slot="end">
                <IonButton></IonButton>
            </IonButtons>
            </IonToolbar>
        </IonHeader>
        {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}

        <IonContent className="ion-padding">
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px white">⚥</div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray text_align_center">
                    { getUserFieldDisplayValue(field_to_edit) }
                </div>
            </IonRow>
            {/* <IonRow>
                <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                    As a mentee, you can learn from our mentors and find your perfect match!
                    As a mentor, you can earn by helping others.
                    You can switch roles later from your profile.
                </div>
            </IonRow> */}
            <IonRow class="wizard_action_row">
                <div className="flex wrap space_evenly">
                    <div className="margin_top_10px">
                            <div className="cbutton_red pointer" onClick={ () => ( changeGenderAndRedirect("man") ) }>{ getGenderDisplay("man") }</div>
                    </div>
                    <div className="margin_top_10px">
                            <div className="cbutton_red pointer" onClick={ () => ( changeGenderAndRedirect("woman") ) }>{ getGenderDisplay("woman") }</div>
                    </div>
                    <div className="margin_top_10px">
                            <div className="cbutton_red pointer" onClick={ () => ( changeGenderAndRedirect("nonbinary") ) }>{ getGenderDisplay("nonbinary") }</div>
                    </div>
                </div>
                {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
            </IonRow>
            


            
            <IonRow>
                {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
          </IonRow>
        </IonContent>
      </IonPage>
    )
}

GenderWizard.defaultProps = {};

export default GenderWizard;