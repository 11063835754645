import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonLoading,
} from "@ionic/react";

import { getSharedId, setSharedId } from '../../apis/courtli/courtli';

import "./Landing2.scss";
import "./Landing2-Mobile.scss";
import { getVersion, setToken, getToken, getBrowser } from '../../apis/courtli/courtli';


type Props = {
  history: any;
};

const Landing2: React.FC<Props> = ({ history }) => {

  const version = getVersion();
  let params = useParams<{ token: string }>();


  useEffect(() => {
    window.scrollTo(0, -1);
    if (params.token) {
      setTokenAndReload(params.token);
    }
  }, []);

  async function setTokenAndReload(token: string) {
    await setToken({ token: token });
    await getToken();
    history.push("/wizards/setup");
  }

  async function goToLogin() {
    history.replace("/login");
  }

  return (
    <IonPage className="dont_force_mobile_aspect_ratio">
      <IonContent forceOverscroll={false} className="lp_body">
        <div className="header_menu">
          <div className="header_logo">
            <img src="https://courtli.com/assets/img/courtli_logo_vertical.svg" alt="Courtli Logo" />
          </div>
          <nav>
            <ul>
              <li><a href="./#home">Home</a></li>
              <li><a href="./#about">About</a></li>
              <li><a href="./#start">Start your journey</a></li>
            </ul>
          </nav>
        </div>
        <div className="header_cta">
          <img src="https://courtli.com/assets/img/courtli_logo_vertical.svg" alt="Courtli Logo" />
          <div className="margin_top_20px">Connection Refined</div>
          <a onClick={() => goToLogin()} className="cta_button margin_top_40px">Log in</a>
        </div>
        <section className="header_section">

        </section>

        <section id="home">
          <div className="section_content">
            <img className="nice_image" src="https://cdn.pixabay.com/photo/2015/08/17/18/23/balloons-892806_960_720.jpg" alt="Two Balloons" />
            <div className="section_paragraph">
              <h2>What is Courtli?</h2>
              <p>Courtli is a dating app that allows you to find your perfect match with the help of your friends, relatives and professional mentors.
                <br />A mentor can teach you how to build a better profile and how to be your best self when communicating with potential matches.
                <br />A mentor can also suggest matches to you as well as vet them to ensure you do not waste your time and don't get your hopes up before a potential match is carefully reviewed.
              </p>
            </div>
          </div>
        </section>
        <section id="about" className="bgreen white">
          <div className="section_content flex_direction_row_reverse">
            <img className="nice_image" src="https://cdn.pixabay.com/photo/2017/08/01/08/16/couple-2563424_960_720.jpg" alt="People Dating" />
            <div className="section_paragraph">
              <h2>Who is Courtli for?</h2>
              <p>
                <b>Mentees</b>
                <br />Courtli is for anyone who wants to find their perfect match and improve their dating skills.
                <br />Whether you're new to dating or just looking for some guidance, our mentors can help you refine your approach and increase your chances of finding true love.
                <br />
                <br /><b>Mentors</b>
                <br />If you are a dating coach, matchmaker, or just someone who is good at helping others find love, you can become a mentor on Courtli.
                <br />You can help your friends and relatives as well as create paid services for the world to see!
              </p>
            </div>
          </div>
        </section>
        <section id="start">
          <div className="section_content">
            <img className="nice_image" src="https://cdn.pixabay.com/photo/2018/01/05/16/24/rose-3063284_960_720.jpg" alt="Flowers" />
            <div className="section_paragraph">
              <h2>How to get started?</h2>
              <p>
                1. Log in with Google, Facebook or SMS
                <br />2. Create a profile
                <br />3. Start your journey
                <br />
                <br />Psst... Our mentors are there to help you every step of the way.
                <br />Make the best of your dating experience with Courtli.
              </p>
            </div>
          </div>
        </section>
        <section id="about" className="bgreen white">
          <div className="section_content flex_direction_row_reverse">
            <img className="nice_image" src="https://cdn.pixabay.com/photo/2019/01/27/22/32/mountains-3959204_960_720.jpg" alt="People Dating" />
            <div className="section_paragraph">
              <h2>What makes Courtli different?</h2>
              <p>Courtli offers a unique and compelling experience that sets it apart from others.
                <br />Experienced mentors help users find matches faster and improve their dating skills.
                <br />Incentives are provided for users to invite friends and relatives to join the app, increasing the likelihood of finding a compatible match.
                <br />The user-friendly interface makes it easy to navigate and find the features you need.
                <br />Overall, our app is the best choice for anyone looking for a meaningful connection.
              </p>
            </div>
          </div>
        </section>
        <section id="download">
          <div className="section_content">
            <div className="t1 display_flex align_items_center">Get started today</div>
            <a href="/login" className="cta_button cta_button_left">Download the App</a>
          </div>
        </section>
        {/* <section>
            <br/>
            <br/>
            <br/>
            <div className="margin_auto text_align_center big_green_ball2">
              <img src="https://courtli.com/assets/img/courtli_logo_vertical.svg" alt="Courtli Logo" />
              <div>Connection Refined</div>
            </div>            
            <br/>
            <br/>
            <br/>
          </section> */}
        <footer>
          <div className="container">
            <div>
              <nav className="footer_menu">
                <ul>
                  <li><a href="#home">Home</a></li>
                  <li><a href="#about">About</a></li>
                  <li><a href="#start">Download</a></li>
                  <li><a href="/terms-and-conditions">Terms of Service</a></li>
                  <li><a href="/privacy-policy">Privacy Policy</a></li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="footer_text text_align_center t5 bgreen width_100p white max_width_none">&copy; 2023 Courtli. All rights reserved.</div>
        </footer>
      </IonContent>
    </IonPage>
  );
};

Landing2.defaultProps = {};

export default Landing2;
