
import React, { Component, useEffect,useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton,
          IonModal,
          IonButtons

} from '@ionic/react';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import {courtli, courtliHealthCheckWithRedirect, changeRole, initOnboarding, getUserRoleDisplay, changeUserField, getAge,
  getOnboardingNextStep,
  getUser} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack, ellipseSharp } from 'ionicons/icons';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
  history: any;
    user: any;
    onClose: () => void;
};

const BirthdayWizard: React.FC<Props> = ({ history, user, onClose }) => {
  
    const [birthdayModalIsOpen, setBirthdayModalIsOpen] = useState(false);
    let [birthday, setBirthday] = useState(946723843000);
    let [age, setAge] = useState(0);
    
    /// VARIABLES
    let facebook_initialized:boolean = false;
    let [nextStep, setNextStep] = useState<any>({
        title: "START NOW",
        wizard: "/tabs/profile"
    });
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {
        courtliHealthCheckWithRedirect(history);
        initOnboarding(setNextStep);        
    }, []);

    async function saveBirthdayAndProceed(){
      /// SAVE BIRTHDAY AND AGE
      console.log("saveBirthdayAndProceed() input", {age: age, birthday: birthday});

      let result = await changeUserField("birthday_at", birthday);
      console.log("saveBirthdayAndProceed() changeUserField() result", result);

      /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
      let next_step = getOnboardingNextStep(await getUser({use_cache: false}));
      console.log("saveBirthdayAndProceed() changeUserField() next_step", next_step);
      history.push(next_step.wizard);

      /// PROCEED      
      setBirthdayModalIsOpen(false);
      // history.goBack();
    }
    function calculateAgeAndSetBirthday(birthday_date:string){

      console.log("calculateAgeAndSetBirthday() birthday_date", birthday_date);
      if(!birthday_date || birthday_date.length === 0){
        return;
      }
      let new_birthday = new Date(birthday_date);
      setBirthday(new_birthday.getTime());

      let new_age = getAge(new_birthday.toString());
      setAge(new_age);
      console.log("calculateAgeAndSetBirthday()", {
        age: new_age,
        birthday: new_birthday.getTime()
      });
    }
    function checkIfBirthdayWizardShouldOpen(){
      console.log("checkIfBirthdayWizardShouldOpen()", {
        age: age,
        birthday: birthday
      });
      if(birthday != 0 && age >= 18){
        setBirthdayModalIsOpen(true);
      }else if(age < 18){
        setBirthdayModalIsOpen(false);
        alert("You must be 18 years of age or older to join Courtli!");        
      }else{
        setBirthdayModalIsOpen(false);
        alert("Please add your birthday first!");
      }
    }

    return (
      <IonPage className="backround_almost_white">
        <IonHeader className="no-border">
            <IonToolbar className="user-profile-toolbar toolbar-no-border ">
              <IonButtons slot="start">
                  <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
              </IonButtons>
              {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
              <IonButtons slot="end">
                  <IonButton></IonButton>
              </IonButtons>
            </IonToolbar>
        </IonHeader>
        {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}
        <IonContent className="ion-padding">
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px">🎂</div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray">
                    Add your birthday
                </div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                    {/* <b>IMPORTANT</b> */}
                    <br/>Your choice will be permanent!
                </div>
            </IonRow>
            <IonRow>
                <input
                  type="date" 
                  className="cdate_input margin_auto pointer margin_top_10px"
                  placeholder="Your birthday"
                  value={new Date(birthday).toISOString().slice(0, 10)}
                  onInput={(e:any) => calculateAgeAndSetBirthday(e.target.value)}
                />
                {/* <DatePicker
                  className="cdate_input margin_auto pointer margin_top_10px"
                  onChange={(date:any) => calculateAgeAndSetBirthday(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/dd/yyyy"
                /> */}
            </IonRow>
            <IonRow class="wizard_action_row">
                <div className="flex">
                    <div className="cbutton_red margin_auto pointer" onClick={ () => ( checkIfBirthdayWizardShouldOpen() ) }>Save</div>
                </div>
                {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
            </IonRow>
            


            
            <IonRow>
                {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
          </IonRow>
          
        {/* CONDFIRMATION MODAL */}
        <IonModal isOpen={birthdayModalIsOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Confirm birthday</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setBirthdayModalIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding bg-gradient">

              <div className="megaemoji margin_auto margin_top_80px">📅</div>

              <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                    <b>CONFIRM YOUR AGE</b>
                    <br/>Age: {age}
              </div>
              <div className="margin_auto margin_top_20px text_align_center t4 color_light_gray">
                  {/* <b>IMPORTANT</b> */}
                  <br/>Your choice will be permanent!
              </div>
              <div className="flex margin_top_20px">
                  <div className="cbutton_red margin_auto pointer" onClick={ () => ( saveBirthdayAndProceed() ) }>Save</div>
              </div>
          </IonContent>
        </IonModal>

        </IonContent>
      </IonPage>
    )
}

BirthdayWizard.defaultProps = {};

export default BirthdayWizard;