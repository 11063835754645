import React, { useState, useEffect, useRef } from "react";
import {
  IonContent,
  IonIcon,
  IonModal,
  IonRow,
} from "@ionic/react";
import {
  chatbubble,
  closeSharp,
  chevronDown,
  heart
} from "ionicons/icons";
import "./ProfileDiscover.scss";
import RandomAvatar from "../../components/RandomAvatar/RandomAvatar";

//FETCH USER
import { getUserById, getFirstInterestsInText, getNoResultsIndicator, getServices } from '../../apis/courtli/courtli';
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import ImagesPreview from "../ImagesPreview/ImagesPreview";
import Chats from "../Chats/Chats";
import ChatsShareModal from "../ChatsShareModal/ChatsShareModal";

type Props = {
  userId: string;
  history?: any;
  onClose: () => void;
  handleClickLike?: () => void;
  handleClickNope?: () => void;
  displayButtons?: [string]
};

const ProfileDiscover: React.FC<Props> = ({ userId, onClose, displayButtons, handleClickLike, handleClickNope, history }) => {


  //USER
  var [user, setUser] = useState<any>({});

  //IMAGES
  const [isImagesPreviewOpen, setImagesPreviewOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  //BUTTONS
  const [displayNope, setDisplayNope] = useState('0');

  //DATA
  const [interestsText, setInterestsText] = useState<any>("None");
  const [images, setImages] = useState<any>("None");
  const [imagesItems, setImagesItems] = useState<any>([]);
  const [services, setServices] = useState<any>([]);

  //LAYOUT
  const [metaHeight, setMetaHeight] = useState('0px');
  const [topOffset, settopOffset] = useState('0px');
  const ref = useRef(null);

  //SHARE MODAL
  const [isChatsOpen, setIsChatsOpen] = useState<boolean>(false);


  //USER
  useEffect(() => {
    if (userId) {
      getUserById(userId).then((response) => {
        setUser(response);
        user = response;
        setData();
      });
      fetchServices();
    }

  }, []);

  //DATA
  async function setData() {
    if (user && user.interests) {
      let interestsText = await getFirstInterestsInText(6, user);
      let interests = interestsText.split(",");
      let badges = interests.map((item: string) => <div key={item} className='badge'>{item}</div>)
      setInterestsText(badges);
    }

    if (user && user.images) {
      let images = user.images.map((item: any, index: number) => <img key={item.url} className='image' src={item.url} onClick={() => { handleOpenImagesPreview(index) }} />)
      let imagesSrc = user.images.map((item: any) => item.url);
      var imageItems = [];

      for (let image of imagesSrc) {
        imageItems.push({
          imageUrl: image
        })
      }
      setImages(images);
      setImagesItems(imageItems);
    }
  }

  //IMAGES

  function handleOpenImagesPreview(index: number) {
    setCurrentImageIndex(index);
    setImagesPreviewOpen(true);
  }

  function closeImagesPreview() {
    setImagesPreviewOpen(false);
  }


  //RESIZE
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  function handleResize() {

    const element = ref.current;
    var offsetTop = 0;

    if (element) {
      offsetTop = element['offsetTop'];
      settopOffset(offsetTop + "px");
    }

    const windowHeight = getViewportHeight();
    setMetaHeight(windowHeight - offsetTop + 'px');
  }

  function getViewportHeight() {
    return window.visualViewport ? window.visualViewport.height : window.innerHeight;
  }


  function handleClickWrapper(type: string) {
    if (handleClickLike && handleClickNope) {
      if (type === "LIKE") {
        onClose();
        handleClickLike();
      } else {
        onClose();
        handleClickNope();
      }
    }
  }

  //CHECK ONLINE
  function isOnline(lastConnection: number) {
    let time = new Date().getTime();
    if (time < lastConnection + 1 * 60 * 1000) {
      return true;
    }
    return false;
  }


  //SERVICES
  async function fetchServices() {
    let services_new = await getServices({
      search: {
        user_id: userId
      }
    });
    setServices(services_new);
  }

  function openService(id: any) {
    onClose();
    history.push("/service/" + id);
  }

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (
    <>

      <IonContent className="profile-page">

        <div className="profile-container">

          <div className="user-content">

            <RandomAvatar customSize={106} src={user.profile_image_url} />

            <div className="action-buttons">

              <div className="small-button">
                {(displayButtons && displayButtons.includes('chat')) &&
                  <IonIcon className='circular-button blue' icon={chatbubble} />
                }
              </div>

              <div className="big-button" /*style={{ opacity: displayNope}}*/ onClick={() => { handleClickWrapper('NOPE') }}>
                {(displayButtons && displayButtons.includes('nope')) &&
                  <IonIcon className='circular-button gray' icon={closeSharp} />
                }
              </div>

              <div className="space">
              </div>

              <div className="big-button" onClick={() => { handleClickWrapper('LIKE') }}>
                {(displayButtons && displayButtons.includes('like')) &&
                  <IonIcon className='circular-button red' icon={heart} />
                }
              </div>

              <div className="small-button" onClick={onClose}>
                <IonIcon className='circular-button green' icon={chevronDown} />
              </div>

            </div>

            <div className="name-label">
              {(isOnline(user.last_online_at)) ? <span className='status-online'>•</span> : <span className='status-offline'>•</span>} {user.age} • {user.first_name} • {user.height}
            </div>

            <div>
              {user.location_description} • <span className="distance-label">{user.distance}</span>
            </div>

            <div>
              {user.job}
            </div>


            <div ref={ref} className="user-meta" style={{ height: metaHeight, paddingBottom: topOffset }}>


              <div className="user-meta-content">

                <div className="card">
                  <span className="card-title">About me</span>
                  <span className="card-content">{user.about_me}</span>
                </div>

                <div className="card">
                  <span className="card-title">What I am looking for</span>
                  <span className="card-content">{user.about_me}</span>
                </div>

                <div className="card">
                  <span className="card-title">Interests</span>
                  <div className='badges'>{interestsText}</div>
                </div>

                <div className="card">
                  <span className="card-title">Education</span>
                  <span className="card-content">{user.about_me}</span>
                </div>

                <div className="card">
                  <span className="card-title">Media</span>
                  <div className='images'>{images}</div>
                </div>

                {(services.length > 0) &&
                  <div className="card">
                    <span className="card-title">Services</span>
                    <div>
                      {
                        services.length === 0 ? getNoResultsIndicator() :
                          services.map((service: any) => {
                            service.user = {
                              id: service.user_id,
                              first_name: service.first_name,
                              age: service.age,
                              profile_image_url: service.profile_image_url,
                              last_online_at: service.last_online_at
                            };
                            return <div key={service.id} className="width_100p margin_top_20px" onClick={() => { openService(service.id) }}>
                              <ServiceCard service={service} history={history} isEditCard={false} />
                            </div>
                          })
                      }
                    </div>
                  </div>
                }

                <IonRow className="share-button">
                  <div>
                    <p className="margin_auto cbutton_red pointer" onClick={() => { setIsChatsOpen(true) }}>SHARE PROFILE</p>
                  </div>
                </IonRow>

                {/* CHATS SHARE MODAL */}
                <IonModal isOpen={isChatsOpen} backdropDismiss={false}>
                  <ChatsShareModal shareUser={user} onClose={() => setIsChatsOpen(false)} />
                </IonModal>

              </div>


            </div>

          </div>

        </div>

      </IonContent>


      <IonModal isOpen={isImagesPreviewOpen}>
        <ImagesPreview
          images={imagesItems}
          onClose={closeImagesPreview}
          index={currentImageIndex}
        />
      </IonModal>


    </>
  );
};

ProfileDiscover.defaultProps = {};

export default ProfileDiscover;
