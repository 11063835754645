
import React, { Component, useEffect, useState } from 'react';
import {
    IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
    IonItem,
    IonLabel,
    IonSpinner

} from '@ionic/react';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import { courtli, courtliHealthCheckWithRedirect, initOnboarding, getUser, resetCache } from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
    history: any;
    user: any;
    onClose: () => void;
};

const SetupWizard: React.FC<Props> = ({ history, user, onClose }) => {


    /// VARIABLES
    let [nextStep, setNextStep] = useState<any>({
        title: "Checking your progress...",
        wizard: "/tabs/profile"
    });
    let [rerunSetupWizard, setRerunSetupWizard] = useState(0);
    let [intervalId, setIntervalId] = useState<any>(false);


    /// EXECUTION    
    useEffect(() => {
        runWizard();

        /// REFRESH INTERVAL
        // intervalId = false;
        // if (getIntervalId()) {
        //     clearInterval(intervalId);
        //     intervalId = false;
        // }
        /// RECHECK EVERY 5 SEC!
        let newIntervalId = setInterval(function () {
            setRerunSetupWizard(new Date().getTime());
        }, 5000);
        setIntervalId(newIntervalId);
        // console.log("SetupWizard useEffect new intervalId", getIntervalId());   
    }, [])

    useEffect(() => {
        // console.log("SetupWizard useEffect intervalId", getIntervalId());
        if (getIntervalId()) {
            runWizard();
        }
    }, [rerunSetupWizard])

    function getIntervalId() {
        return intervalId;
    }
    useEffect(() => {

        // console.log("startSetupWizard() nextStep", nextStep);
        if (nextStep && nextStep.redirect) {
            history.replace(nextStep.redirect);
            /// THIS IS THE FINAL STEP SO STOP AUTO REFRESH HERE
            clearInterval(getIntervalId());
            intervalId = false;
            console.log("SetupWizard STOP AUTO REFRESH");
        }

    }, [nextStep])

    function runWizard() {
        setNextStep({
            title: "Checking your progress...",
            wizard: "/tabs/profile"
        });
        courtliHealthCheckWithRedirect(history);
        startSetupWizard();

    }
    async function startSetupWizard() {
        await resetCache();
        await initOnboarding(setNextStep);
    }
    function goToNextStep() {
        history.replace(nextStep.wizard);
    }
    return (
        <IonPage className="wizard-page setup-page">
            <IonContent className="ion-padding">
                <IonRow>
                    <div className="top-emoji">👋</div>
                </IonRow>
                <IonRow>
                    <div className="top-title">
                        Hi there!
                    </div>
                </IonRow>
                <IonRow>
                    <div className="top-description">
                        Please set up your account! By adding as much information about yourself as you can you will increase your chances to match with other people and get greater chance to meet with best-of-the-best mentors on our platform!
                    </div>
                </IonRow>
                <IonRow className="wizard_action_row">
                    <div className="margin_auto margin_top_40px cbutton_red pointer" onClick={goToNextStep}>{nextStep.title}</div>

                    {
                        nextStep.can_skip &&
                        <div className="margin_auto margin_top_10px t5 color_light_gray pointer" onClick={() => { history.replace('/tabs/discover') }}>Skip for now</div>
                    }

                </IonRow>



                <IonRow>
                    {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
                </IonRow>
            </IonContent>
        </IonPage>
    )
}

SetupWizard.defaultProps = {};

export default SetupWizard;