import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router";
import {
  IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonButton, IonIcon, IonContent, IonText, IonFooter, IonModal, IonAlert, IonSelect, IonSelectOption
} from '@ionic/react';
import {
  shield, checkmarkCircle, heartOutline, chevronBack, ellipsisVertical
} from 'ionicons/icons';
import RandomAvatar from '../../components/RandomAvatar/RandomAvatar';
import InputWithGiphy from '../../components/InputWithGiphy/InputWithGiphy';
import './Chat.scss';
import OrderCard from '../../components/OrderCard/OrderCard';

//REPORT
import ReportUser from "../ReportUser/ReportUser";

//KEYBOARD
import { Capacitor } from "@capacitor/core";

import {
  courtli,
  compareJson,
  getToken,
  setToken,
  getMessages,
  enrichMessageFromToken,
  getUser,
  getFriendUser,
  sendMessage,
  getPrettyDateAndTime,
  getDDMMYYYY,
  logSeenMessages,
  checkPathMatch,
  getPrettyDate,
  goBack,
  getOrders,
  decodeHtml
} from '../../apis/courtli/courtli';
import { platform } from 'os';
import ReactDOM from 'react-dom';
import ProfileDiscover from '../ProfileDiscover/ProfileDiscover';
import SharedProfile from '../../components/SharedProfile/SharedProfile';

type Props = {
  history: any
}


const Chat: React.FC<Props> = ({ history }) => {

  //let [messageContainerHeight, setMessageContainerHeight] = useState(0);
  //let [keyboardIsActive, setKeyboardIsActive] = useState(false);

  let params = useParams<{ id: string }>();
  // console.log("Chat params", params);
  let [messages, setMessages] = useState<any[]>([]);
  const contentRef = useRef<React.RefObject<HTMLIonContentElement>>(null);
  let [friend_user, setFriendUser] = useState<any>(false);

  const [user_avatar, setUserAvatar] = useState<any>(<></>);
  const [user_name, setUserName] = useState<string>("Courtli 2023");
  const [chatTitleMessage, setChatTitleMessage] = useState<string>("Loading...");

  let lastDatePrinted = "";
  let noDateStampForThisDay = true;
  let intervalId: any;
  let user: any;


  //REPORT USER
  const [isReportUserOpen, setIsReportUserOpen] = useState<boolean>(false);
  const handleViewReportUser = () => {
    setIsReportUserOpen(true);
  };

  //KEYBOARD
  var initWindowHeight = 0;

  useEffect(() => {

    scrollToBottom(0, true);
    fetchData();

    window.addEventListener("click", handleClickOnInputField);
    // set up an interval to refresh the API every 3 sec
    intervalId = setInterval(() => {
      loadMessages(friend_user);
    }, 3000);

    // cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("click", handleClickOnInputField);
    };
  }, [params.id]);


  //KEYBOARD
  const [headerTopMargin, setHeaderTopMargin] = useState('0px');

  useEffect(() => {

    //GENERIC SOLUTION
    initWindowHeight = window.innerHeight;

    //TODO NATIVE APP
    /*
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidShow', info => {
        console.log('keyboard did show with height:', info.keyboardHeight);
      });

      Keyboard.addListener('keyboardDidHide', () => {
        console.log('keyboard did hide');
      });
    }
    */

    if (Capacitor.isNativePlatform()) {
      window.addEventListener('resize', handleResize);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  function handleResize() {
    const windowHeight = getViewportHeight();
    const keyboardHeight = initWindowHeight - Math.round(windowHeight);
    //setHeaderTopMargin(keyboardHeight + 'px');
    if (initWindowHeight < (windowHeight + 1)) {
      //scrollToBottom(0, false);
    } else {
      //scrollToBottom(0, true);
    }
  }

  function scrollToTop(element: any) {
    while (element) {
      element.scrollTop = 0;
      element = element.parentElement;
    }
  }

  function getViewportHeight() {
    return window.visualViewport ? window.visualViewport.height : window.innerHeight;
  }


  function checkIfDateStampPrinted(message: any) {

    // console.log("Chat checkIfDateStampPrinted() message", message);
    let date = new Date(message.created_at);
    let message_date = date.getMonth() + "/" + date.getDate();
    // console.log("Chat checkIfDateStampPrinted() lastDatePrinted", lastDatePrinted);
    // console.log("Chat checkIfDateStampPrinted() message_date", message_date);
    if (lastDatePrinted) {
      /// CHECK IF DATE OF MESSAGE IS NEW
      if (message_date != lastDatePrinted) {
        lastDatePrinted = message_date;
        noDateStampForThisDay = true;
      } else {
        noDateStampForThisDay = false;
      }
    } else {
      noDateStampForThisDay = true;
      lastDatePrinted = message_date;
    }
    return "";
  }

  /// FUNCTIONS INSIDE useEffect()
  async function fetchData() {

    user = await getUser({ use_cache: true });
    let friend_user_new = await loadFriendUser();
    // console.log("Chat loadData() friend_user_new", friend_user_new);
    if (!friend_user_new) {
      console.error("Chats NO FRIEND USER");
      alert("The user associated with this chat was not found.");
      history.goBack();
    }
    setFriendUser(friend_user_new);
    friend_user = friend_user_new;
    if (!friend_user) {
      return false;
    }

    // console.log("Chat loadData() user", user);
    await loadMessages(friend_user_new);
    return true;
  }


  async function loadFriendUser() {

    let new_friend_user: any = false;
    if (params.id === "0") {
      // new_friend_user = await getUser({use_cache: true});
      new_friend_user = {
        id: "0",
        first_name: "Courtli 2023",
        created_at: new Date(),
        current_role: "mentor",
        profile_image_url: courtli.design.logo_url
      }
    } else {
      new_friend_user = await getFriendUser({ friend_user_id: params.id });
    }
    if (!new_friend_user) {
      console.error("Chat loadFriendUser() NO FRIEND USER");
      return false;
    }

    //console.log("Chat loadFriendUser() new_friend_user", new_friend_user);
    let chat_created_at = getDDMMYYYY(new_friend_user.created_at);
    setChatTitleMessage("YOU MATCHED WITH " + new_friend_user.first_name + " ON " + chat_created_at);
    setUserAvatar(<RandomAvatar size="sm" src={new_friend_user.profile_image_url} />);
    setUserName(new_friend_user.first_name);
    // console.log("Chat loadFriendUser() new_friend_user", new_friend_user)
    setFriendUser(new_friend_user);

    return new_friend_user;
  }

  async function loadMessages(friend_user_new: any) {

    // console.log("Chat loadMessages() friend_user_new", friend_user_new);
    let new_messages = await getMessages({ friend_user_id: params.id, limit: 20 });
    // console.log("Chat loadMessages() new_messages", new_messages);
    // let new_messages:any = MESSAGES2;

    if (params.id === "0") {
      /// GET COURTLI MESSAGES AND THEN NEW STUFF ON TOP
      // console.log("Chat loadMessages() 0 new_messages", new_messages);
      let courtli_messages = await getMessages({ friend_user_id: "courtli", limit: 20 });
      new_messages = courtli_messages.concat(new_messages);

      /// ONLY THE FIRST 3 MESSAGE HAS TO BE ENRICHED
      new_messages[0] = await enrichMessageFromToken(new_messages[0]);
      new_messages[1] = await enrichMessageFromToken(new_messages[1]);
      new_messages[2] = await enrichMessageFromToken(new_messages[2]);

    }

    // console.log("Chat loadMessages() new_messages", new_messages);
    let new_messages_for_ui = await remapMessagesFromDBToUI(new_messages, friend_user_new);
    // let new_messages_for_ui = new_messages;
    // console.log("Chat loadMessages() new_messages_for_ui", new_messages_for_ui);     
    let old_messages = JSON.parse(JSON.stringify(messages));
    // console.log("Chat loadMessages() old_messages", old_messages);     

    if (!compareJson(old_messages, new_messages_for_ui)) {
      // console.log("Chat loadMessages() new messages found, rerendering messages", {old_messages: old_messages, new_messages_for_ui: new_messages_for_ui});
      // messages = new_messages_for_ui;
      // console.log("Chat loadMessages() new_messages_for_ui", new_messages_for_ui);
      setMessages(new_messages_for_ui);
      /// FOR SOME REASON YOU MUST ALSO SET THE VARIABLE ITSELF. setMessages does not change the variable...
      logSeenMessages(new_messages);
      messages = new_messages_for_ui;

      // setTimeout(() => {
      //   //scrollToBottom();
      // }, 100);

    } else {
      // console.log("Chat loadMessages() NO new messages found, no action");
    }

  }

  async function remapMessagesFromDBToUI(new_messages: any, friend_user_new: any) {

    // console.log("Chat remapMessagesFromDBToUI() new_messages", new_messages);
    let new_msgs = await Promise.all(
      new_messages.map(async (message: any) => {
        return await prepareMessageContent(message, friend_user_new);
      })
    );
    // console.log("Chat remapMessagesFromDBToUI() new_msgs", new_msgs);

    return new_msgs;
  }

  async function prepareMessageContent(message: any, friend_user_new: any) {

    /// PREPARE BODY
    // console.log("Chat prepareMessageContent() body", body);
    let body = message.body;
    let type = message.type;
    body = decodeHtml(body);
    if (body.indexOf("<OrderCard") >= 0) {
      /// THIS IS AN ORDER
      // console.log("Chat prepareMessageContent() body", body);
      let order_id = getOrderIdFromString(body);
      // console.log("Chat prepareMessageContent() order_id", order_id);
      let order = await getOrders({ id: order_id });
      order = order[0];
      // console.log("Chat prepareMessageContent() order", order);
      type = "order_card";
      body = <OrderCard order={order} viewer={user} history={history} />;
    } else {
      body = nl2br(body.trim());
    }

    /// PREPARE OTHER STUFF
    let isSender = message.from.toString() === user.id.toString() ? true : false;
    let message_bubble_class = "chat-text chat-item-outgoing";
    if (!isSender) {
      if (friend_user_new.current_role === "mentor") {
        message_bubble_class = "chat-text mentor-message-background";
      } else {
        message_bubble_class = "chat-text mentee-message-background";
      }
    }

    /// PREPAR FINAL OUTPUT
    let message_obj = {
      id: message.id,
      isSender: isSender,
      message_bubble_class: message_bubble_class,
      type: type, // 'text' or 'image'
      body: body,
      profile_image_url: friend_user_new.profile_image_url,
      timestamp: getPrettyDateAndTime(message.created_at),
      created_at: message.created_at,
      footer: getMessageStatusDisplay(message)
    };
    // console.log("Chat prepareMessageContent() message_obj", message_obj);
    return message_obj;
  }

  function getOrderIdFromString(str: any) {
    const regex = /order_id='(\d+)'/;
    const match = str.match(regex);
    if (match && match[1]) {
      return Number(match[1]);
    }
    return null;
  }

  function getMessageStatusDisplay(message: any) {
    if (message.seen_at > 0) {
      return "Seen " + getPrettyDateAndTime(message.seen_at);
    } else if (message.received_at > 0) {
      return "Received " + getPrettyDateAndTime(message.received_at);
    } else {
      return "Sent " + getPrettyDateAndTime(message.created_at);
    }
  }
  const handleSubmitMessage = (data: any) => {

    let new_message = {
      friend_user_id: friend_user.id,
      type: data.type,// text || image
      body: data.type.toUpperCase() === 'IMAGE' ? data.imageUrl : data.message
    };
    sendMessage(new_message);
    setMessages(prev => [...prev, {
      id: prev.length,
      isSender: true,
      type: new_message.type, // 'text' or 'image'
      body: new_message.body.trim(),
      profile_image_url: friend_user.profile_image_url,
      message_bubble_class: "chat-text chat-item-outgoing",
      timestamp: getPrettyDateAndTime(new Date().getTime()),
      created_at: new Date().getTime(),
      footer: "Sent " + getPrettyDateAndTime(new Date().getTime())
    }]);

    handleClickOnInputField();

    setTimeout(() => {
      scrollToBottom();
      // fakeReply();
    }, 200);
  }

  const fakeReply = () => {
    setMessages(prev => [...prev, {
      id: prev.length,
      isSender: false,
      avatar: 'assets/img/avatars/ionwizard.png',
      type: 'text',
      body: 'Wow Nice. ',
      timestamp: 'Mar 30, 2021 9:57am',
      created_at: new Date().getTime(),
      footer: "Sent  " + getPrettyDateAndTime(new Date().getTime())
    }]);

    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }

  const scrollToBottom = (duration: number = 300, isFirstLoad: boolean = false) => {
    if (isFirstLoad) {
      setTimeout(() => {
        if (contentRef && contentRef.current) {
          // @ts-ignore
          contentRef.current.scrollToBottom(duration);
        }
      }, 500);
    } else {
      if (contentRef && contentRef.current) {
        // @ts-ignore
        contentRef.current.scrollToBottom(duration);
      }
    }
  }

  const nl2br = (text: string) => {
    if (!text) return text;

    return text.replace(/\n/ig, '<br>');
  }
  function goToFriendUserProfile() {
    if (friend_user.current_role === "mentor") {
      history.push("/mentor/" + friend_user.id)
    } else {
      history.push("/profile/" + friend_user.id)
    }
  }

  function handleClickOnInputField() {
    setTimeout(() => {
      handleResize()
    }, 300)
  }
  function handleGoBack() {
    //handleClickOnInputField();
    /// DO NOT USE GO BACK BECAUSE THIS CAN CAUSE DEAD ENDS
    //  goBack(history, {refresh: false}); 
    history.push("/tabs/chats");
  }

  function printMessages() {
    // console.log("Chat printMessages() messages", messages);
    let msgs = messages.map((item: any) => (

      <div key={item.id}>
        {
          checkIfDateStampPrinted(item)
        }
        {
          noDateStampForThisDay &&
          <div className="chat-timestamp">
            <IonText>
              {getPrettyDate(item.created_at)}
            </IonText>
          </div>
        }
        <div className={`chat-item${item.isSender ? ' chat-item-outgoing' : ''}`} key={item.id}>
          <div className="chat-item-inner">

            <div className={`chat-body ${item.type === 'order_card' ? '  chat_body_with_order_card' : ''}`}>
              <div className={`chat-item-bubble${item.type === 'image' ? ' bubble-image' : ''}${item.type === 'order_card' ? ' width_100p' : ''}`}>
                {
                  item.type === 'order_card' &&
                  item.body
                }
                {
                  item.type === 'text' &&
                  <div className={item.message_bubble_class} dangerouslySetInnerHTML={{ __html: item.body }} />
                }
                {
                  item.type === 'shareUser' &&
                  <SharedProfile data={item.body}></SharedProfile>
                }
                {
                  item.type === 'image' &&
                  <img src={item.body} alt="" />
                }
              </div>

              {
                item.isSender &&
                <div className="chat-item-status">
                  <IonIcon icon={checkmarkCircle} color="secondary" />
                  {item.footer}
                </div>
              }
            </div>

            {
              !item.isSender && item.type === 'image' &&
              <div className="chat-item-reaction">
                <IonIcon icon={heartOutline} />
              </div>
            }
          </div>
        </div>
      </div>
    ));

    // console.log("msgs", msgs);

    setTimeout(() => {
      scrollToBottom();
    }, 100);
    return msgs;
  }

  //ALERT 
  const [isAlertVisible, setAlertVisible] = useState(false);


  //SELECTION
  const handleSelectChange = (event: any) => {
    let option = event.detail.value;
    if (option == "Delete") {
      setAlertVisible(true);
    }

    if (option == "Delete & Report") {
      handleViewReportUser();
    }
  };

  const [selected, setSelected] = useState([]);

  const clearSelected = () => {
    setSelected([]);
  };

  //PROFILE MODAL
  const [isProfileOpen, setProfileOpen] = useState(false);
  function handleOpenProfile() {
    setProfileOpen(!isProfileOpen);
  }
  function handleToggleProfile() {
    setProfileOpen(!isProfileOpen);
  }

  /// EXECUTE
  return (

    <IonPage className="chat-page" style={{ paddingTop: headerTopMargin }}>

      <IonHeader className='no-border'>
        <IonToolbar className='toolbar-no-border toolbar-no-safe-area'>

          <IonButtons slot='start'>
            <IonButton onClick={() => handleGoBack()}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>

          <IonTitle onClick={() => { handleToggleProfile() }}>
            <div className='avatar-container'>
              {user_avatar}
              <div className="user-name">{user_name}</div>
            </div>
          </IonTitle>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
              <IonSelect
                className='select-options'
                interface="popover"
                placeholder=""
                onIonChange={handleSelectChange}
                value={selected}
                onIonDismiss={clearSelected}
              >
                <IonSelectOption>Delete</IonSelectOption>
                <IonSelectOption>Delete & Report</IonSelectOption>
              </IonSelect>

            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>

      { /* @ts-ignore: TS2739 */}
      <IonContent className="safe-area-bottom" ref={contentRef} fullscreen>
        <div className="chat-top" >
          <div className='chat-title'><IonText>{chatTitleMessage}</IonText></div>
        </div>
        <div className="chat-list">
          {
            printMessages()
          }
        </div>
      </IonContent>

      <IonFooter>
        <InputWithGiphy onChange={handleSubmitMessage} />
      </IonFooter>

      {/* MODALS */}
      <IonModal isOpen={isReportUserOpen} swipeToClose>
        <ReportUser
          user={friend_user}
          onClose={() => setIsReportUserOpen(false)}
        />
      </IonModal>

      <IonAlert
        isOpen={isAlertVisible}
        cssClass={"custom-alert"}
        header="Are you sure you want to delete this user from your friends list?"
        buttons={[
          {
            text: 'Nope',
            role: 'cancel',
            handler: () => {
              setAlertVisible(false);
            },
          },
          {
            text: 'Delete',
            role: 'confirm',
            handler: () => {
              handleGoBack();
            },
          },
        ]}
      ></IonAlert>

      <IonModal isOpen={isProfileOpen} swipeToClose >
        <ProfileDiscover
          userId={friend_user.id}
          onClose={handleToggleProfile}
        />
      </IonModal>

    </IonPage>

  );
};

Chat.defaultProps = {

}

export default Chat;
