import React, { useState, useEffect } from 'react';

import './ScoutingOptions.scss';

import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItem, IonNote, IonRow, IonModal } from '@ionic/react';
import { chevronBack, phonePortraitOutline, radioButtonOff, mailOutline, logoWhatsapp, radioButtonOn } from 'ionicons/icons';
import ScoutingInvitation from '../ScoutingInvitation/ScoutingInvitation';

type Props = {
    user: any,
    onClose: () => void,
}


const ScoutingOptions: React.FC<Props> = ({ user, onClose }) => {

    const [isScoutingOpen, setIsScoutingOpen] = useState<boolean>(false);
    const [scoutingOption, setIsScoutingOption] = useState("");

    /// EXECUTION
    useEffect(() => {

    }, []);

    return (
        <>

            <IonHeader className="no-border">
                <IonToolbar className="toolbar-no-border bg-light">
                    <IonTitle>Invitation form</IonTitle>
                    <IonButtons slot="start">
                        <IonButton onClick={onClose}>
                            <IonIcon icon={chevronBack} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="scouting-options-page bg-light">

                <IonRow>
                    <div className="top-emoji">💌</div>
                </IonRow>

                <IonList className="list-custom">

                    <IonItem className='top-note' lines="none">
                        <IonNote>Invite your <span className='red-text'>friend, family member</span> or trusted person to help you find matches faster!</IonNote>
                    </IonItem>

                    <br />

                    <IonItem lines="none" onClick={() => { setIsScoutingOption('sms') }}>
                        <IonIcon className='red-icon' icon={phonePortraitOutline} slot="start" />
                        <IonNote>Invite via SMS</IonNote>
                        {scoutingOption != 'sms' && <IonIcon className='gray-circular-icon' icon={radioButtonOff} slot="end" />}
                        {scoutingOption == 'sms' && <IonIcon className='red-icon' icon={radioButtonOn} slot="end" />}
                    </IonItem>

                    <IonItem lines="none" onClick={() => { setIsScoutingOption('email') }}>
                        <IonIcon className='red-icon' icon={mailOutline} slot="start" />
                        <IonNote>Invite via Email</IonNote>
                        {scoutingOption != 'email' && <IonIcon className='gray-circular-icon' icon={radioButtonOff} slot="end" />}
                        {scoutingOption == 'email' && <IonIcon className='red-icon' icon={radioButtonOn} slot="end" />}
                    </IonItem>

                    <IonItem lines="none" onClick={() => { setIsScoutingOption('whatsapp') }}>
                        <IonIcon className='red-icon' icon={logoWhatsapp} slot="start" />
                        <IonNote>Invite via Whatsapp</IonNote>
                        {scoutingOption != 'whatsapp' && <IonIcon className='gray-circular-icon' icon={radioButtonOff} slot="end" />}
                        {scoutingOption == 'whatsapp' && <IonIcon className='red-icon' icon={radioButtonOn} slot="end" />}
                    </IonItem>
                </IonList>

                {(scoutingOption != '') &&
                    <IonRow className="next-button">
                        <div>
                            <p className="margin_auto cbutton_red pointer" onClick={() => { setIsScoutingOpen(true); }}>NEXT</p>
                        </div>
                    </IonRow>
                }

            </IonContent>

            {/* MODAL - SCOUTING INVITATION */}
            <IonModal isOpen={isScoutingOpen}>
                <ScoutingInvitation user={user} scoutingOption={scoutingOption} onClose={() => { setIsScoutingOpen(false); }} onCloseAll={() => { setIsScoutingOpen(false); onClose() }} />
            </IonModal>

        </>
    );
};

ScoutingOptions.defaultProps = {

}

export default ScoutingOptions;
