import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonBadge,
} from '@ionic/react';
import { stop, chatbubbles, colorFilter } from 'ionicons/icons';
import './Tabs.scss';
import Chats from '../Chats/Chats';
import Discover from '../Discover/Discover';
import ProfileEdit from '../../pages/ProfileEdit/ProfileEdit';


import Services from '../Services/Services';


/// COURTLI
import { courtliHealthCheckWithRedirect, getCards, getAgeFromBirthday, getUser, getMessagesFromDB } from '../../apis/courtli/courtli';


type Props = {
  history: any,
  profile_image_url: any
}


const Tabs: React.FC<Props> = ({ history }) => {


  let intervalId: any = false;

  const [cards, setCards] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState<number>(0);

  async function fetchUser() {
    let user_new = await getUser({ use_cache: true });
    // console.log("Tabs fetchUser() user_new", user_new);

    setUser(user_new);
    // console.log("Tabs fetchUser() user", user);
    return;
  }
  async function fetchCards() {

    let cards_new: any = [];
    setCards(cards_new);
    // console.log("Tabs fetchCards() cards", cards);
    return;
  }


  useEffect(() => {

    // console.log("Tabs useEffect() history", history);
    courtliHealthCheckWithRedirect(history);
    fetchUser();
    fetchCards();
    intervalId = setInterval(() => {
      if (true) {
        refreshUnseenMessages();
      }
    }, 5000);
  }, []);

  async function refreshUnseenMessages() {
    let unseen_messages = await getMessagesFromDB({
      seen_at: 0,
      created_at_min: new Date().getTime() - 1000 * 60 * 60 * 24 * 14
    });
    // console.log("Tabs refreshUnseenMessages() unseen_messages", unseen_messages);
    if (unseen_messages) {
      setUnseenMessagesCount(unseen_messages.length);

      if (unseen_messages.length > 0) {
        document.title = 'Courtli (' + unseen_messages.length + ')';
      } else {
        document.title = 'Courtli';
      }
    } else {
      clearInterval(intervalId);
    }
  }


  return (
    <IonTabs className="tabs-top" >
      <IonRouterOutlet >
        <Redirect path="/tabs" to="/tabs/discover" exact />
        <Route path="/tabs/discover" component={Discover} exact />
        <Route path="/tabs/profile" component={ProfileEdit} exact />
        <Route path="/tabs/chats" component={Chats} exact />
        <Route path="/tabs/services" component={Services} exact />

        {/* <Redirect to="/" /> */}

      </IonRouterOutlet>

      <IonTabBar slot="bottom" className="tabs-page tab-bar-no-border">
        <IonTabButton tab="discover" href="/tabs/discover" >
          <img src="assets/img/navigation/match.svg" width="24" alt="" className="tab-icon-active" />
          <img src="assets/img/navigation/match_disabled.svg" width="24" alt="" className="tab-icon-inactive" />
          {/* <IonBadge color="primary">{unseenMatchesCount}</IonBadge> */}
        </IonTabButton>
        {/* <IonTabButton tab="me" href="/tabs/discover">
          <IonIcon icon={ colorFilter } />
        </IonTabButton> */}
        <IonTabButton tab="services" href="/tabs/services">
          <img src="assets/img/navigation/learn.svg" width="24" alt="" className="tab-icon-active" />
          <img src="assets/img/navigation/learn.svg" width="24" alt="" className="tab-icon-inactive grayscale" />
        </IonTabButton>
        {/* <IonTabButton tab="services" href="/profile">
          <img className="tab-icon-inactive grayscale" src="assets/img/navigation/learn.svg" width="24" alt="" />
          <img className="tab-icon-active" src="assets/img/navigation/learn.svg" width="24" alt="" />
        </IonTabButton> */}
        <IonTabButton tab="matches" href="/tabs/chats">
          <img src="assets/img/navigation/chat.svg" width="24" alt="" className="tab-icon-active" />
          <img src="assets/img/navigation/chat.svg" width="24" alt="" className="tab-icon-inactive grayscale" />
          {/* <IonIcon icon={ chatbubbles } /> */}
          {unseenMessagesCount > 0 && <IonBadge color="primary">{unseenMessagesCount}</IonBadge>}
        </IonTabButton>

      </IonTabBar>
    </IonTabs>
  );
};

Tabs.defaultProps = {};

export default Tabs;
