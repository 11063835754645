import React, { useState, useRef, useEffect } from 'react';
import {
  IonRow, IonCol, IonButton, IonIcon
} from '@ionic/react';
import {
  star
} from 'ionicons/icons';
import './SharedProfile.scss';


import { timeAgo, getUserById } from '../../apis/courtli/courtli';

type Props = {
  data: string,
}

const SharedProfile: React.FC<Props> = ({ data }) => {

  let [user, setUser] = useState<any>({});
  let [message, setMessage] = useState<string>("");

  useEffect(() => {
    try {
      var json = JSON.parse(data);
      getSharedUser(json.userId);
      setMessage(json.userId);
    } catch (error) {
      console.log(error);
    }
  }, []);


  function getSharedUser(userId: string) {
    getUserById(userId).then((response) => {
      setUser(response);

      console.log(response);
    });
  }

  return (
    <div
      className="person-card background-img"
      style={{ backgroundImage: `url('${user.profile_image_url}')` }}
    >
      <div className="card-caption">
        <IonRow className="ion-align-items-end">
          <IonCol className="caption-text">
            <div className="caption-title">
              {user.name} {user.age}
            </div>
            <div className="caption-time">
              {timeAgo(user.last_online_at)}
            </div>
          </IonCol>
          {/* <IonCol size="auto">
            <IonButton color="white" className="button-custom button-icon button-xs button-star">
              <IonIcon slot="icon-only" icon={star} />
            </IonButton>
          </IonCol> */}
        </IonRow>
      </div>
    </div>
  );
};

SharedProfile.defaultProps = {

}

export default SharedProfile;


