
import React, { Component, useEffect, useState } from 'react';
import {
    IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
    IonItem,
    IonLabel,
    IonSpinner,
    IonBackButton,
    IonButtons

} from '@ionic/react';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import { courtli, courtliHealthCheckWithRedirect, changeRole, initOnboarding, getUserRoleDisplay, getOnboardingNextStep, getUser, goToUrlAndReload } from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack } from 'ionicons/icons';
import './Wizards.scss';

/// CONSTANTS
type Props = {
    history: any;
    user: any;
    onClose: () => void;
};

const RoleWizard: React.FC<Props> = ({ history, user, onClose }) => {


    /// VARIABLES
    let [nextStep, setNextStep] = useState<any>({
        title: "START NOW",
        wizard: "/tabs/profile"
    });
    /// FUNCTION DEFINITIONS


    /// EXECUTION
    useEffect(() => {
        courtliHealthCheckWithRedirect(history);
        initOnboarding(setNextStep);
    }, [])


    //ROLE SELECTION
    const [selectedRole, setSelectedRole] = useState('');
    const [mentorSelected, setMentorSelected] = useState('');
    const [menteeSelected, setMenteeSelected] = useState('');

    useEffect(() => {
        if (selectedRole == 'mentor') {
            setMentorSelected('button-selected');
            setMenteeSelected('');
        }
        if (selectedRole == 'mentee') {
            setMentorSelected('');
            setMenteeSelected('button-selected');
        }
    }, [selectedRole])


    async function changeRoleAndRedirect(role: string) {
        let response: any = await changeRole(role);

        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
        let user_new = await getUser({ use_cache: false });
        console.log("RoleWizard user_new", user_new);

        if (response.response_code === 1) {
            if (user_new.current_role !== role) {
                console.log("RoleWizard user_new current_role mismatch! This was previously caused by service worker caching the API response. Fixing manually");
                user_new.current_role = role;
            }
        } else {
            alert("Error changing role - please try again later.");
        }
        let next_step = getOnboardingNextStep(user_new);
        console.log("RoleWizard next_step", next_step);
        console.log("RoleWizard goToUrlAndReload()");
        goToUrlAndReload(history, next_step.wizard);
    }

    async function saveRole() {
        if (selectedRole != "") {
            changeRoleAndRedirect(selectedRole);
        } else {
            alert("Please select a role to continue");
        }
    }


    return (
        <IonPage className="wizard-page">
            <IonHeader className="no-border">
                <IonToolbar className="user-profile-toolbar toolbar-no-border ">
                    <IonButtons slot="start">
                        <IonBackButton text={""} icon={chevronBack} defaultHref="/login" />
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen={true}>
                <IonRow>
                    <div className="top-emoji">🎲</div>
                </IonRow>
                <IonRow>
                    <div className="top-title">
                        Role
                    </div>
                </IonRow>
                <IonRow>
                    <div className="top-description">
                        You  <span className='red-label'>cannot</span> change this option later in the app!
                    </div>
                </IonRow>
                <IonRow>
                    <div className="inputs-container">
                        <div className={"select-button " + mentorSelected} onClick={() => (setSelectedRole('mentor'))}>
                            <div className="title">MENTOR</div>
                            <div className="description">I want to help others find matches faster!</div>
                        </div>
                        <div className={"select-button " + menteeSelected} onClick={() => (setSelectedRole('mentee'))}>
                            <div className="title">REGULAR USER</div>
                            <div className="description">I would like to find my perfect match!</div>
                        </div>
                    </div>
                </IonRow>
                <IonRow class="wizard_action_row">
                    <div className="flex">
                        <div className="margin_auto  cbutton_red pointer" onClick={() => (saveRole())}>SAVE</div>
                    </div>
                </IonRow>
            </IonContent>
        </IonPage>
    )
}

RoleWizard.defaultProps = {};

export default RoleWizard;