import React, { useState, useRef, useEffect } from 'react';
import useScript from '../../hooks/useScript';
import {
  IonHeader, IonRange, IonToolbar, IonTitle, IonButtons, IonButton, IonSegment, IonSegmentButton, IonLabel, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonList, IonListHeader, IonItem, IonToggle, IonTextarea, IonNote, IonInput, IonText, IonModal, IonAvatar, IonBadge,
} from '@ionic/react';
import { eye, accessibility, lockClosedSharp, calendarOutline, maleFemaleOutline, chevronExpand, addOutline, briefcase, school, fingerPrint, create, maleOutline, femaleOutline, rocketOutline, rocket, chevronBack } from 'ionicons/icons';
import SwipeCard from '../../components/SwipeCard/SwipeCard';

import { courtli, changeUserFieldFromInput, getGenderDisplay, getFirstInterestsInText, getUserInterests } from '../../apis/courtli/courtli';
import './ProfileEditPublic.scss';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Profile from '../Profile/Profile';
// import * as jQuery from 'jquery';
// import '../../apis/dropzone/dropzone.css';

// import '../../apis/dropzone/dropzone.js';

type Props = {
  user: any,
  onClose: () => void,
  history: any
}


const ProfileEditPublic: React.FC<Props> = ({ user, onClose, history }) => {


  const [distance, setDistance] = useState<number>(30);
  const [ageRange, setAgeRange] = useState<any>({
    lower: 20,
    upper: 30
  })
  const stackRef = useRef<HTMLDivElement>(null);
  const [segmentView, setSegmentView] = useState<string>('EDIT');
  const [interestsText, setInterestsText] = useState<any>("None");
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);


  const handleNoMoreSlide = (isOnTheLeft: boolean = true) => {
    if (stackRef && stackRef.current) {
      const className = isOnTheLeft ? 'rotate-left' : 'rotate-right';

      // @ts-ignore
      stackRef.current.classList.add(className);
      setTimeout(() => {
        // @ts-ignore
        stackRef.current.classList.remove(className);
      }, 250);
    }
  }

  const handleOpenInterests = () => {
    history.push("/wizards/interests");
    onClose();
  }
  const openGenderWizard = (field_to_edit: string) => {
    history.push("/wizards/gender/" + field_to_edit);
    onClose();
  }

  const handleViewProfile = () => {
    setIsProfileOpen(true);
  };

  /// EXECUTION
  useEffect(() => {
    fetchData();

    user.profile_published = isProfilePublished();
  }, []);

  async function fetchData() {
    if (user && user.extra_data) {
      let interests_text_new = await getFirstInterestsInText(6, user);
      let interests = interests_text_new.split(",");
      let badges = interests.map((item: string) => <IonButton key={item} className='badge'>{item}</IonButton>)
      setInterestsText(badges);
    }
  }

  function isProfilePublished() {

    let state: any = 0;
    if (user.current_role === "mentee") {
      state = user.mentee_profile_public;
    } else if (user.current_role === "mentor") {
      state = user.mentor_profile_public;
    }

    state = state === 1 ? true : false;
    // console.log("isProfilePublished() user", user);
    // console.log("isProfilePublished() state", state);
    return state;
  }

  //console.log(user);

  return (
    <>

      <IonHeader className="no-border">
        <IonToolbar className="toolbar-no-border ">
          <IonTitle>Public Settings</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="profile-edit-page bg-light">

        <div className="full-height">

          <div className="segment-view">

            <div className="me-header" onClick={handleViewProfile}>
              <div className="progress-holder">
              </div>
              <IonAvatar className="avatar" >
                <img src={user.profile_image_url} alt="" />
              </IonAvatar>
              <div className="detail-holder">
                <span className="me-title">{user.first_name}</span>
              </div>
              <div className="me-info">
                {user.age} • {user.location_description} •
              </div>
            </div>


            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>GENERAL INFORMATION</IonLabel>
              </IonListHeader>

              <IonItem lines="none" onClick={(e: any) => { alert("You cannot change your name!"); }}>
                <IonIcon icon={accessibility} slot="start" />
                <IonNote>{user.first_name}</IonNote>
                <IonIcon icon={lockClosedSharp} slot="end" />
              </IonItem>

              <IonItem lines="none" onClick={() => { history.push("/wizards/name"); onClose(); }}>
                <IonIcon icon={chevronExpand} slot="start" />

                {(user.extra_data.heightFt != null && user.extra_data.heightIn != null) &&
                  <IonNote className="card-user-height">{user.extra_data.heightFt}</IonNote>
                }
                {(user.extra_data.heightFt == null || user.extra_data.heightIn == null) &&
                  <IonNote>Height</IonNote>
                }


                <IonIcon icon={lockClosedSharp} slot="end" />
              </IonItem>

              <IonItem lines="none" onClick={(e: any) => { alert("Age depends on your birthday and cannot be changed!"); }}>
                <IonIcon icon={calendarOutline} slot="start" />
                <IonNote>{user.age} Years old</IonNote>
                <IonIcon icon={lockClosedSharp} slot="end" />
              </IonItem>

              {/* TODO CREATE GENDER LIST*/}
              <IonItem lines="none">
                <IonIcon icon={maleFemaleOutline} slot="start" />
                <IonNote>I am a {getGenderDisplay(user.extra_data?.my_gender)}</IonNote>
                <IonIcon icon={lockClosedSharp} slot="end" />
              </IonItem>

              <IonItem lines="none">
                <IonIcon icon={briefcase} slot="start" />
                <IonNote className='editable'>Job</IonNote>
                <IonIcon className='red-circular-icon' icon={addOutline} slot="end" />
              </IonItem>

              <IonItem lines="none">
                <IonIcon icon={school} slot="start" />
                <IonNote className='editable'>Studies</IonNote>
                <IonIcon className='red-circular-icon' icon={addOutline} slot="end" />
              </IonItem>

              <IonItem className='variable-height' lines="none">
                <IonIcon icon={fingerPrint} slot="start" />
                <IonTextarea rows={4} value={user.about_me} onClick={() => { history.push("/wizards/about-me"); onClose(); }} />
                <IonIcon className='red-icon' icon={create} slot="end" />
              </IonItem>

              <IonItem className='variable-height' lines="none" onClick={handleOpenInterests}>
                <IonIcon icon={rocket} slot="start" />
                <div className='badges'>{interestsText}</div>
                <IonIcon className='red-circular-icon' icon={addOutline} slot="end" />
              </IonItem>

            </IonList>



            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>ADDITIONAL INFORMATION</IonLabel>
              </IonListHeader>

              <IonItem lines="none" onClick={(e: any) => { history.push("/wizards/publish"); onClose(); }}>
                <IonNote className='editable'>Profile is visible to others</IonNote>
                {user.profile_published && <IonIcon className='red-icon' icon={eye} slot="end" />}
                {!user.profile_published && <IonIcon icon={eye} slot="end" />}
              </IonItem>

              <IonItem lines="none" onClick={() => { openGenderWizard("extra_data.looking_for"); onClose(); }}>
                <IonNote className='editable'>I am looking for {getGenderDisplay(user.extra_data?.looking_for)}</IonNote>
                {getGenderDisplay(user.extra_data?.looking_for) == 'Man' && <IonIcon className='red-icon' icon={maleOutline} slot="end" />}
                {getGenderDisplay(user.extra_data?.looking_for) == 'Woman' && <IonIcon className='red-icon' icon={femaleOutline} slot="end" />}
                {getGenderDisplay(user.extra_data?.looking_for) == 'Nonbinary' && <IonIcon className='red-icon' icon={maleFemaleOutline} slot="end" />}
              </IonItem>

            </IonList>

            <br />
            <br />
          </div>


          {
            segmentView === 'PREVIEW' &&
            <div className="segment-view preview-container full-height">
              <div className="card-border full-height" ref={stackRef}>
                <SwipeCard
                  user={user}
                  isPreview
                  onNoMoreSlide={handleNoMoreSlide}
                />
              </div>
            </div>
          }

        </div>

        <IonModal isOpen={isProfileOpen} swipeToClose>
          <Profile
            user={user}
            onClose={() => setIsProfileOpen(false)}
            handleClickLike={() => { return false }}
            handleClickNope={() => { return false }}
            likeButtonsEnabled={false}
          />
        </IonModal>

      </IonContent>
    </>
  );
};

ProfileEditPublic.defaultProps = {

}

export default ProfileEditPublic;
