import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonModal
} from "@ionic/react";
import { chevronBack, checkmarkDone, arrowForward, star } from "ionicons/icons";
import MentorAvatar from '../../components/MentorAvatar/MentorAvatar';
import Reviews from '../../pages/Reviews/Reviews';
import OrderCheckout from '../../pages/OrderCheckout/OrderCheckout';

import "./Service.scss";
import {
  getUser,
  getServices,
  getPriceTypeDisplay,
  createOrder,
  decodeHtml
} from '../../apis/courtli/courtli';

//MENTOR
import { getMentorLevel } from '../../apis/courtli/courtli';


type Props = {
  history: any;
};

const Service: React.FC<Props> = ({ history }) => {

  const [isReviewsOpen, setIsReviewsOpen] = useState<boolean>(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState<boolean>(false);
  let params = useParams<{ service_id: string }>();
  let service_id = params.service_id;
  // console.log("Service service_id", service_id);

  let [service, setService] = useState<any>(false);


  useEffect(() => {

    fetchData();

  }, []);

  async function fetchData() {
    // await getUser();
    let services_new = await getServices({ id: service_id });
    // console.log("Services fetchData() services_new", services_new);
    if (services_new[0]) {
      let service_new = services_new[0];
      service_new.user = {
        id: service_new.user_id,
        first_name: service_new.first_name,
        age: service_new.age,
        profile_image_url: service_new.profile_image_url,
        last_online_at: service_new.last_online_at,
        level: service_new.level
      };
      setService(service_new);
    } else {
      alert("Sorry, this service is not found.");
    }
  }

  async function orderNow() {
    //console.log("Service orderNow() service", service);

    /// CHECK THAT YOU DONT ORDER FROM YOURSELF
    let user = await getUser({ use_cache: true });
    //console.log("Service orderNow() user", user);
    if (service.user_id.toString() === user.id.toString()) {
      alert("Sorry, you cannot order from yourself.");
      return;
    }
    /// GO TO CHECKOUT PAGE
    setIsCheckoutOpen(true);
  }

  async function executeOrder() {
    let res = await createOrder(service.id);
    if (res.response_code === 1) {
      history.push("/order-made/" + service.user_id);
    } else {
      alert("Sorry, you cannot make an order at this time. Please try later.");
    }
  }
  return (
    <IonPage className="service-page">

      <IonHeader className="no-border">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">
          <IonButtons slot="start">
            <IonBackButton text={""} icon={chevronBack} defaultHref="/tabs/services" />
          </IonButtons>
          <IonTitle className="ion-text-center">Service</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <div className="safe-area-bottom">

          <img src={service.title_image_url} className="service-image" />
          <div className="service-title">
            <span>{decodeHtml(service.name)}</span>
          </div>

          {service &&
            <div className="service-cost">
              <span>$ {Number(service.price).toFixed(2)} {getPriceTypeDisplay(service.price_type)}</span>
            </div>
          }

          {
            <div className="reviews-button"
              onClick={() => setIsReviewsOpen(true)}>
              <span className="semibold"><IonIcon className='star-icon' icon={star} />{Math.round(service.rating_stars * 10) / 10}</span>
              <div className="red-button">reviews <IonIcon icon={arrowForward} /></div>
            </div>
          }


          <div className="section-title">Description</div>
          <div className="section-description">{decodeHtml(service.description)}</div>
          <div className="section-title">About seller</div>

          {service.user &&
            <div>
              <IonRow class='mentor-container'>
                <div className="ctiny_avatar">
                  <img src={service.user.profile_image_url} />
                </div>
                <IonCol class='mentor-info'>
                  <div className='mentor-name'>{service.user.first_name} <IonIcon icon={checkmarkDone} /></div>
                  <div className='mentor-level'>{getMentorLevel(service.user)} mentor,</div>
                </IonCol>
              </IonRow>
              <div className='mentor-description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.</div>
            </div>
          }


          <div className="similar-services">
            <div className="section-title">Similar services from mentor</div>
            <div className="empty-space"></div>
            <div className="red-button">view all <IonIcon icon={arrowForward} /></div>
          </div>




          {/*

          <div className=" margin_top_30px width_90p">
            <div className="t3 semibold">About mentor
              <div className="float_right t5 margin_top_13px">
                <span className="red semibold" onClick={() => { history.push("/mentor/" + service.user.id) }}>view profile →</span>
              </div>
            </div>
            {service && <MentorAvatar user={service.user} history={history} />}
            <div className="t4">{decodeHtml(service.about_me)}</div>
          </div>

          */}


        </div>

        <IonRow class="big_action_button">
          <div className="flex">
            <div className="cbutton_red margin_auto pointer cbutton_95p" onClick={() => (orderNow())}>Get now!</div>
          </div>
        </IonRow>

      </IonContent>

      <IonModal isOpen={isReviewsOpen} className="custom-modal-small">
        <Reviews service={service} onClose={() => setIsReviewsOpen(false)} history={history} />
      </IonModal>

      <IonModal isOpen={isCheckoutOpen} className="custom-modal-small">
        <OrderCheckout service={service} onClose={() => setIsCheckoutOpen(false)} history={history} executeOrder={executeOrder} />
      </IonModal>
    </IonPage >
  );
};

Service.defaultProps = {};

export default Service;
