import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonAvatar,
  IonPage
} from "@ionic/react";
import {
  arrowDown,
  checkmarkOutline,
  briefcaseOutline,
  locationOutline,
  musicalNote,
  play,
  closeSharp,
  star,
  heartSharp,
  chevronBack,
  filterSharp,
  closeOutline,
  closeCircleOutline,
  arrowUndo,
  glassesOutline,
  create
} from "ionicons/icons";
import ProfileImageSlides from "../../components/ProfileImageSlides/ProfileImageSlides";
import "./ImagesPreview.scss";
import {
  getActiveInterestsBadgesForProfile,
  decodeHtml
} from '../../apis/courtli/courtli';


type Props = {
  images: any;
  index: number;
  onClose: () => void;
};

const ProfilePreview: React.FC<Props> = ({ images, index, onClose }) => {

  const [badges, setBadges] = useState<any>("None");
  const [likeButtonsEnabled2, setLikeButtonsEnabled2] = useState<boolean>(false);


  useEffect(() => {

  }, []);

  return (
    <>

      <IonPage className="profile-page">

        <IonHeader className='no-border'>
          <IonToolbar className='profile-toolbar toolbar-no-border toolbar-no-safe-area'>
            <IonButtons slot='start'>
              <IonButton onClick={onClose}>
                <div className='filter-button'>
                  <IonIcon icon={chevronBack} />
                </div>
              </IonButton>
            </IonButtons>
          </IonToolbar>

        </IonHeader>

        <IonContent className="profile-bg">
          <ProfileImageSlides images={images} index={index} />
          <div className="mask"></div>
        </IonContent>

      </IonPage >

    </>
  );
};

ProfilePreview.defaultProps = {};

export default ProfilePreview;
