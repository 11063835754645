
import React, { Component, useEffect,useState } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton,
          IonButtons,
          useIonAlert

} from '@ionic/react';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import {
    courtli, 
    courtliHealthCheckWithRedirect, 
    changeRole, 
    initOnboarding, 
    getUserRoleDisplay,
    getOnboardingNextStep, 
    getUser,
    changeUserFieldAlsoExtraData,
    goToUrlAndReload,
    publishUser    } from '../../apis/courtli/courtli';

import { Fireworks } from '@fireworks-js/react'

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack } from 'ionicons/icons';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
    history: any;
    user: any;
    onClose: () => void;
};

const PublishWizard: React.FC<Props> = ({ history, user, onClose }) => {


    /// VARIABLES
    const [presentAlert] = useIonAlert();
    let facebook_initialized:boolean = false;
    let [nextStep, setNextStep] = useState<any>({
        title: "START NOW",
        wizard: "/tabs/profile"
    });
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {

        courtliHealthCheckWithRedirect(history);
        initOnboarding(setNextStep);
        
    }, [])

    async function publishAndRedirect(state:number){
       
        let q = "Are you sure you want to unpublish your account?";
        if(state){
            q = "Are you sure you want to publish your account?";
        }
        presentAlert({
            header: q,
            buttons: [
                {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                    alert("Hjuuh - close call. Action stopped.");
                },
                },
                {
                text: 'OK',
                role: 'confirm',
                handler: async () => {
                    
                    let user_new = await getUser({use_cache: false});

                    let result = {
                        response_code: 2,
                        info: "Failed to connect"
                    }
                    if(user_new.current_role === "mentee"){
                        result = await publishUser("mentee_profile_public", state);
                    }else{
                        result = await publishUser("mentor_profile_public", state);
                    }

                    console.log("PublishWizard result", result);
                    if(result.response_code !== 1){
                        alert(result.info);
                        /// STOP HERE
                        return;
                    }
                    if(state){
                        alert("Profile published!");
                        console.log("PublishWizard user_new", user_new);                     
                        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
                        let next_step = getOnboardingNextStep(user_new);
                        console.log("PublishWizard next_step", next_step);
                        goToUrlAndReload(history, next_step.wizard);
                        // history.push(next_step.wizard);
                    }else{
                        // history.push("/tabs/discover");
                        alert("Profile unpublished!");
                        goToUrlAndReload(history, "/tabs/discover");
                    }
                },
                },
            ]
        });
    }

    return (
      <IonPage className="backround_almost_white">
        <IonHeader className="no-border">
            <IonToolbar className="user-profile-toolbar toolbar-no-border ">
            <IonButtons slot="start">
                <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
            </IonButtons>
            {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
            <IonButtons slot="end">
                <IonButton></IonButton>
            </IonButtons>
            </IonToolbar>
        </IonHeader>
        {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}
        
        
        <IonContent className="ion-padding fireworks_container">
            <Fireworks
                options={{
                    rocketsPoint: {
                        min: 40,
                        max: 60
                    }
                }}
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    background: 'white',
                    zIndex: 1
                }}
            />
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px position_relative z100">🚀</div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray position_relative z100">
                    Publish your profile
                </div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray position_relative z100">
                    Make your profile visible to other Courtli users!
                </div>
            </IonRow>
            <IonRow class="wizard_action_row z100">
                <div className="flex">
                    <div className="margin_auto cbutton_red pointer" onClick={ () => ( publishAndRedirect(0) ) }>Unpublish</div>
                    <div className="margin_auto cbutton_red pointer" onClick={ () => ( publishAndRedirect(1) ) }>Publish</div>
                </div>
                {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
            </IonRow>
            


            
            <IonRow>
                {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
            </IonRow>
        </IonContent>
      </IonPage>
    )
}

PublishWizard.defaultProps = {};

export default PublishWizard;