import React, { useEffect, useState, useRef } from 'react';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine';
import {
  IonSlides, IonSlide, IonIcon
} from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import './CardImageSlides.scss';
import useSwipe from '../../utils/swipeListener';
import { Direction } from 'swing';


type Props = {
  images: any[],
  isClickable?: boolean,
  onNoMoreSlide?: (l: boolean) => void,
  onChange?: (i: number) => void,
}

const CardImageSlides: React.FC<Props> = ({ images, isClickable, onChange, onNoMoreSlide }) => {

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [currentEnd, setCurrentEnd] = useState<number>(-1);
  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null);


  //IMAGE BULLETS
  const [imageBullets, setImageBullets] = useState<any>("<div></div>");


  useEffect(() => {
    setBullets(activeIndex);
  }, []);


  function setBullets(index: number) {
    var imageBullets = images.map((item: any, i: number) => {
      return <div key={item + i} className="bullet" data-src={i} style={{ background: i == index ? 'white' : '#ffffff3f' }}></div>
    });
    setImageBullets(imageBullets);
  }

  const handleSlideLoaded = () => {
    if (ionSlidesRef && ionSlidesRef.current) {
      ionSlidesRef.current.update();
    }
  }

  const handleSlideChange = () => {
    if (ionSlidesRef && ionSlidesRef.current) {
      ionSlidesRef.current.getActiveIndex()
        .then((index: number) => {
          setActiveIndex(index);
          if (onChange) onChange(index);
          setBullets(index);
        });
    }
  }

  const handleMoveSlide = (step: number = 1) => {
    if (ionSlidesRef && ionSlidesRef.current) {
      if (step === -1) {
        ionSlidesRef.current.slidePrev();
      } else if (step === 1) {
        ionSlidesRef.current.slideNext();
      }

      if (step === currentEnd) {
        // Could not go next or prev
        onNoMore(currentEnd === -1);
      } else {
        setCurrentEnd(0);
        TapticEngine.selection();
      }
    }
  }

  const onNoMore = (isOnTheLeft: boolean) => {
    if (onNoMoreSlide) onNoMoreSlide(isOnTheLeft);
    TapticEngine.notification({ type: 'warning' });
  }

  const handleReachStart = () => {
    setCurrentEnd(-1);
  }
  const handleReachEnd = () => {
    setCurrentEnd(1);
  }


  //SWIPE
  const swipeHandlers = useSwipe({
    onSwipedUp: () => {
      handleMoveSlide(1);
    },
    onSwipedDown: () => {
      handleMoveSlide(-1);
    }
  });

  const slideOpts: any = { direction: 'vertical' }

  if (images.length === 0) return null;

  return (
    <div className="card-image-slides" >
      <IonSlides
        className="slides"
        ref={ionSlidesRef}
        onIonSlidesDidLoad={handleSlideLoaded}
        onIonSlideDidChange={handleSlideChange}
        onIonSlideReachStart={handleReachStart}
        onIonSlideReachEnd={handleReachEnd}
        options={slideOpts}
      >
        {
          images.map(item => (
            <IonSlide key={item.id}>
              <div className="slide-img background-img" style={{ backgroundImage: `url('${item.imageUrl}')` }} />
            </IonSlide>
          ))
        }
      </IonSlides>
      <div className="custom-pagination">
        {
          images?.map((item, index) => (
            <div key={item.id} className={`pagination-bullet${activeIndex === index ? ' pagination-bullet-active' : ''}`} />
          ))
        }
      </div>

      {/*
      {
        isClickable &&
        <div className="overlay-navigation">
          <div className="navi navi-up" onClick={() => handleMoveSlide(-1)}></div>
          <div className="navi navi-down" onClick={() => handleMoveSlide(1)}></div>
        </div>
      }
      */}

      <div className="image-bullet-container">

        <div className="nav-icon-container up" onClick={() => handleMoveSlide(-1)}></div>

        <IonIcon
          className="nav-icon up"
          icon={chevronUp}
        />
        {imageBullets}
        <IonIcon
          className="nav-icon down"
          icon={chevronDown}
        />
        <div className="nav-icon-container down" onClick={() => handleMoveSlide(1)}></div>
      </div>

    </div>
  );
};

CardImageSlides.defaultProps = {
  isClickable: false,
  onNoMoreSlide: () => { },
  onChange: () => { },
}

export default CardImageSlides;
