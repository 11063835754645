
// @ts-nocheck

import React, { Component, useEffect, useState } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonButtons,
          IonBackButton

} from '@ionic/react';

import {
  shield, checkmarkCircle, heartOutline, chevronBack
} from 'ionicons/icons';
import {courtli, loginWithGoogleToken, setToken, goToFirstPage} from '../../../apis/courtli/courtli';
///import { Plugins } from '@capacitor/core';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';




/// STYLESHEETS
import '../../../theme/common.css';
import './Google.css';


/// CONSTANTS


type Props = {
  history: any;
    user: any;
    onClose: () => void;
};

const Google: React.FC<Props> = ({ history, user, onClose }) => {
  
    
    /// VARIABLES
    let [ initialized, setInitialized ] = useState<number>(0);

    /// FUNCTION DEFINITIONS
    
    async function handleLoginClick(){
      
      if(initialized < 2){
        setInitialized(initialized+1);
        initGoogle();
      }else{
        return;
      }
    }
    async function initGoogle(){
        
        // console.log("Running initGoogle()");
        
        let client_id = '1047188707693-4l6cjua2ltm0p203f5chtas13rukp8en';
        GoogleAuth.initialize({
            clientId: client_id, /// + '.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true
        });

        const response:any = await GoogleAuth.signIn();
        // console.log("initGoogle() response", response);

        if (response.authentication && response.authentication.accessToken) {
          
            // Login successful.
            let token = response.authentication.accessToken;
            // console.log(`Access token ${token}`);
            /// SEND TO BACKEND FOR VERIFICATION
            let login_result:any = await loginWithGoogleToken(response);
            console.log("Google login_result", login_result);
            if(login_result.data.data){
              setToken({token: login_result.data.data.token});
            }
            /// FORWARD TO FIRST PAGE
            window.gtag("event", "login_google", {
                event_category: "access",
                event_label: "login"
            });
            goToFirstPage(history);
            
        }
        
        // console.log(`Google user's profile is `, response);
        
        return response;
    }


    /// EXECUTION
    useEffect(() => {
      
      runLogin();
    }, []);

    async function runLogin(){
        setInitialized(0);
        await initGoogle();
        // setTimeout(() => {
        //   initGoogle();
        // }, 2000);
      // }
    }
    return (
      <IonPage>
        <IonHeader className="no-border">
            <IonToolbar className="user-profile-toolbar toolbar-no-border ">
              <IonButtons slot="start">
                  <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
              </IonButtons>
            </IonToolbar>
        </IonHeader>
        {/* <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Connecting</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonContent className="ion-padding">
          <IonRow>
            <IonCol className="text-center">
              <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
              <IonImg className="title-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/250px-Google_2015_logo.svg.png" ></IonImg>
              <IonText className="width_50p display_block margin_auto">Please make sure the login popup is not blocked by your browser.</IonText>
              {
                initialized > 0 &&
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
              }
            </IonCol>
          </IonRow>
          
          <IonRow>
              {
                !initialized &&
                // <IonButton onClick={handleLoginClick} className="margin_auto margin_top_80px">Sign in</IonButton>
                <img src="/assets/img/google.png" onClick={handleLoginClick} className="margin_auto margin_top_80px" />
              }
            
          </IonRow>
          {/* <IonRow>
            <IonCol className="text-center">
              <IonText className="title">
                Facebook Login in Capacitor app
              </IonText>
            </IonCol>
          </IonRow> */}
          {/* <IonRow>
            <IonCol className="text-center">
              <IonText className="text-center">
                By Enappd Team
              </IonText>
            </IonCol>
          </IonRow>
          <IonButton className="login-button" onClick={() => initFacebook()} expand="full" fill="solid" color="primary">
            Login with Facebook
          </IonButton> */}
        </IonContent>
      </IonPage>
    )
}

Google.defaultProps = {};

export default Google;