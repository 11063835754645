import React, { useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import CardSection from '../../components/CardSection/CardSection';

import { 
    addCard
} from '../../apis/courtli/courtli';


type Props = {
    onClose: () => void
}

  
const AddCardForm: React.FC<Props> = ({ onClose }) => {


  const stripe = useStripe();
  const elements = useElements();
  
  const handleSubmit = async (event:any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card:any = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      stripeTokenHandler(result.token);
    }
  };
  const stripeTokenHandler = async (token:any) => {
    // Insert the token ID into the form so it gets submitted to the server
    // const form = document.getElementById('payment-form');

    console.log("stripeTokenHandler() token", token);
    let res = await addCard({token: token.id});
    if(res.response_code === 1){
        console.log("stripeTokenHandler() res", res);
        alert("Your credit card has been added!");
        onClose();
    }else{
        console.log("stripeTokenHandler() res", res);
        alert(res.response_message);
    }

  }

  return (
    <form onSubmit={handleSubmit}>
      {stripe && <CardSection stripe={stripe} /> }
      
      <button className="cbutton_green width_95p margin_auto margin_top_40px display_block" disabled={!stripe}>Add Card</button>
      <div className="t4 text_align_center margin_top_10px ">We use Stripe to ensure secure and reliable payments.</div>
    </form>
  );
}

AddCardForm.defaultProps = {

}

export default AddCardForm;