import React, { useState, useEffect } from 'react';
import {
  IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardContent, IonIcon, IonText, IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonToggle, IonListHeader, IonNote, IonRange,
  IonInput,
  useIonAlert,
  IonModal
} from '@ionic/react';
import {
  moonOutline,
  handRightOutline,
  cardOutline,
  lockClosedSharp,
  phonePortraitOutline,
  mailOutline,
  compassOutline,
  calendarOutline,
  sunnySharp,
  contrastOutline,
  moonSharp,
  repeatOutline,
  handLeftOutline,
  chevronForward,
  documentsOutline,
  documentOutline,
  logOutOutline,
  refreshCircleOutline,
  closeOutline,
  chevronBack,
  addCircleOutline,
  logInOutline
} from 'ionicons/icons';
import ThemeService from '../../services/theme.service';
import './ProfileEditPrivate.scss';
import {
  getVersion,
  deleteUser,
  deleteFromLocalStorage,
  goToUrlAndReload
} from '../../apis/courtli/courtli';

import RefreshServiceWorkerButton from "../../components/ServiceWorkerRefreshButton/ServiceWorkerRefreshButton";
import { repeat } from 'rxjs';
import ScoutingOptions from '../ScoutingOptions/ScoutingOptions';
type Props = {
  history: any,
  user: any,
  onClose: () => void,
}

const ProfileEditPrivate: React.FC<Props> = ({ onClose, user, history }) => {
  const [presentAlert] = useIonAlert();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(ThemeService.getCurrentSetting());
  const [isScoutingOpen, setIsScoutingOpen] = useState<boolean>(false);

  const handleLogout = () => {
    /// KILL SESSION
    deleteFromLocalStorage();
    onClose();
    history.replace('/login');
  }

  const handleToggleDarkTheme = (isDarkMode: boolean) => {
    setIsDarkMode(isDarkMode);
  }

  useEffect(() => {
    ThemeService.toggleDarkMode(isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    user.birthday_date = "MM/DD/YYYY";
    if (user.birthday_at) {
      user.birthday_date = new Date(user.birthday_at).toISOString().split('T')[0];
    }

    console.log(user);
  }, []);


  function openLocationWizard() {
    history.push("/wizards/location");
    onClose();
  };

  function openPhoneWizard() {
    history.push("/wizards/phone");
    onClose();
  };
  function openHandWizard() {
    history.push("/wizards/hand");
    onClose();
  };
  function openCardWizard() {
    history.push("/cards");
    onClose();
  }


  const handleDeleteUser = () => {

    presentAlert({
      header: 'Are you sure you want to delete your account? THIS ACTION WILL BE PERMANENT!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert("Hjuuh - close call. Account deletion stopped.");
            onClose();
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            console.log("DELETING USER FOR REAL");
            actuallyDeleteUser();
            deleteFromLocalStorage();
            onClose();
            setTimeout(() => {
              window.location.reload();
            }, 500);
          },
        },
      ]
      // onDidDismiss: (e: CustomEvent) => { 
      //   console.log("DISMISSED");
      //   // alert("Hjuuh - close call. Account deletion stopped.")
      // }
    })

  }

  const actuallyDeleteUser = () => {
    deleteUser();
    goToUrlAndReload(history, "/login");
  }



  return (
    <>
      <IonHeader>
        <IonToolbar className="toolbar-no-border">
          <IonTitle>Private settings</IonTitle>

          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>


        </IonToolbar>
      </IonHeader>

      <IonContent className="settings-page bg-light">

        <IonList className="list-custom">

          <IonListHeader>
            <IonLabel>CONTACT INFORMATION</IonLabel>
          </IonListHeader>

          <IonItem lines="none" onClick={() => { openPhoneWizard() }}>
            <IonIcon icon={phonePortraitOutline} slot="start" />
            <IonNote>{user.phone || "Add phone"}</IonNote>
            <IonIcon icon={lockClosedSharp} slot="end" />
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={mailOutline} slot="start" />
            <IonNote>{user.email}</IonNote>
            <IonIcon icon={lockClosedSharp} slot="end" />
          </IonItem>

          <IonListHeader className="help-block">
            <IonLabel>Verified contacts help secure your account.</IonLabel>
          </IonListHeader>

          <IonListHeader>
            <IonLabel>PERSONAL INFORMATION</IonLabel>
          </IonListHeader>

          <IonItem lines="none" onClick={() => { openLocationWizard() }}>
            <IonIcon icon={compassOutline} slot="start" />
            <IonNote>{user.location_description || "Unknown"} </IonNote>
            <IonIcon icon={lockClosedSharp} slot="end" />
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={calendarOutline} slot="start" />
            <IonNote>{user.birthday_date} </IonNote>
            <IonIcon icon={lockClosedSharp} slot="end" />
          </IonItem>

          <IonListHeader>
            <IonLabel>PREFERENCES</IonLabel>
          </IonListHeader>

          {!isDarkMode &&
            <IonItem lines="none" onClick={() => { handleToggleDarkTheme(true) }}>
              <IonIcon icon={contrastOutline} slot="start" />
              <IonNote className='editable'>Light mode</IonNote>
              <IonIcon class='orange-icon' icon={sunnySharp} slot="end" />
            </IonItem>
          }

          {isDarkMode &&
            <IonItem lines="none" onClick={() => { handleToggleDarkTheme(false) }}>
              <IonIcon icon={contrastOutline} slot="start" />
              <IonNote className='editable'>Dark mode</IonNote>
              <IonIcon class='orange-icon' icon={moonSharp} slot="end" />
            </IonItem>
          }

          {user.is_right_handed == 0 &&
            <IonItem lines="none" onClick={() => { openHandWizard() }}>
              <IonIcon icon={handLeftOutline} slot="start" />
              <IonNote className='editable'>Left handed</IonNote>
              <IonIcon className='red-icon' icon={repeatOutline} slot="end" />
            </IonItem>
          }

          {user.is_right_handed == 1 &&
            <IonItem lines="none" onClick={() => { openHandWizard() }}>
              <IonIcon icon={handRightOutline} slot="start" />
              <IonNote className='editable'>Right handed</IonNote>
              <IonIcon className='red-icon' icon={repeatOutline} slot="end" />
            </IonItem>
          }

          <IonListHeader>
            <IonLabel>PAYMENTS</IonLabel>
          </IonListHeader>

          <IonItem lines="none" onClick={() => { openCardWizard() }}>
            <IonIcon icon={cardOutline} slot="start" />
            <IonNote className='editable'>Cards</IonNote>
            <IonIcon className='red-circular-icon' icon={chevronForward} slot="end" />
          </IonItem>

          <IonListHeader>
            <IonLabel>LEGAL</IonLabel>
          </IonListHeader>

          <IonItem lines="none" onClick={() => { history.push("/privacy-policy"); onClose(); }}>
            <IonIcon icon={documentsOutline} slot="start" />
            <IonNote className='editable'>Privacy Policy</IonNote>
            <IonIcon className='red-circular-icon' icon={chevronForward} slot="end" />
          </IonItem>

          <IonItem lines="none" onClick={() => { history.push("/terms-and-conditions"); onClose(); }}>
            <IonIcon icon={documentOutline} slot="start" />
            <IonNote className='editable'>Terms & Conditions</IonNote>
            <IonIcon className='red-circular-icon' icon={chevronForward} slot="end" />
          </IonItem>

          <IonListHeader>
            <IonLabel>ACCOUNT SETTINGS</IonLabel>
          </IonListHeader>

          <IonItem lines="none" onClick={() => { handleLogout() }}>
            <IonIcon icon={logOutOutline} slot="start" />
            <IonNote>Logout</IonNote>
            <IonIcon className='red-circular-icon' icon={chevronForward} slot="end" />
          </IonItem>

          <IonItem className='green-item' lines="none" onClick={() => { setIsScoutingOpen(true) }}>
            <IonIcon icon={logInOutline} slot="start" />
            <IonNote>Invite trusted scout</IonNote>
            <IonIcon className='' icon={addCircleOutline} slot="end" />
          </IonItem>

          <IonItem className='red-item' lines="none" onClick={() => { handleDeleteUser() }}>
            <IonIcon icon={logOutOutline} slot="start" />
            <IonNote>Delete account</IonNote>
            <IonIcon className='white-circular-icon' icon={closeOutline} slot="end" />
          </IonItem>

          <RefreshServiceWorkerButton />

        </IonList>


        <div className="ion-text-center ion-padding">
          <div>
            {/* <img src="assets/img/logowords.svg" height="16" alt="" /> */}
          </div>
          <div>Courtli {getVersion()}</div>
        </div>

        {/* <div className="safe-area-bottom">
          <IonList className="list-custom">
            <IonItem className="ion-text-center" button detail={false} lines="none">
              <IonLabel>Delete Account</IonLabel>
            </IonItem>
          </IonList>
        </div> */}


        <IonModal isOpen={isScoutingOpen}>
          <ScoutingOptions user={user} onClose={() => { setIsScoutingOpen(false); }} />
        </IonModal>

      </IonContent>
    </>
  );
};

ProfileEditPrivate.defaultProps = {

}

export default ProfileEditPrivate;
