import React, { useEffect, useState } from 'react';
import {
  IonPage, IonHeader, IonToolbar, IonContent, IonIcon, IonRow, IonButton, IonButtons, IonTitle, IonList, IonItem, IonNote, IonCol
} from '@ionic/react';

import './ChatsShareModal.scss';

//ICONS
import { chevronBack } from 'ionicons/icons';

// COURTLI
import { courtli, getMentors, getLoadingIndicator, getNoResultsIndicator, getUser, getMessagesFromDB, getInboxes, getMatches, sendMessage } from '../../apis/courtli/courtli';
import RandomAvatar from '../../components/RandomAvatar/RandomAvatar';


type Props = {
  shareUser: any,
  onClose: () => void,
}

const ChatsShareModal: React.FC<Props> = ({ shareUser, onClose }) => {

  //MATCHES
  let [matchesToShow, setMatchesToShow] = useState<any>(false);
  let [selectedMatch, setSelectedMatch] = useState<string>('');

  const [user, setUser] = useState<any>({
    profile_image_url: ""
  });


  useEffect(() => {

    console.log(shareUser);

    fetchData();
    refreshInbox();
  }, []);


  async function fetchData() {
    let user_new = await getUser({ use_cache: true });
    setUser(user_new);
    refreshMatches();
  }

  async function refreshInbox() {
    refreshMatches();
  }

  async function refreshMatches() {

    let allMatches = [
      {
        id: "0",
        first_name: 'Courtli',
        profile_image_url: courtli.design.logo_url,
        current_role: "mentor"
      }
    ];
    let inbox_api = await getInboxes();
    let matches_api = await getMatches();
    matches_api = combineUniqueUsers(inbox_api, matches_api);
    allMatches = allMatches.concat(matches_api);

    var matches = [];
    for (let match of allMatches) {
      var item = {
        id: match.id,
        first_name: match.first_name,
        profile_image_url: match.profile_image_url,
        current_role: match.current_role,
        selected: false
      };
      matches.push(item);
    }

    setMatchesToShow(allMatches);
  }

  function combineUniqueUsers(users1: any, users2: any) {
    const combinedUsers = [...users1, ...users2];
    const uniqueUsers = combinedUsers.filter(
      (user, index, self) => index === self.findIndex((u) => u.id === user.id)
    );
    return uniqueUsers;
  }


  function toggleUser(userId: string) {

    var matches = [];
    for (let match of matchesToShow) {
      var item = {
        id: match.id,
        first_name: match.first_name,
        profile_image_url: match.profile_image_url,
        current_role: match.current_role,
        selected: false
      };

      if (match.id == userId) {
        setSelectedMatch(match.id);
        item.selected = true;
      }

      matches.push(item);
    }

    setMatchesToShow(matches);
  }

  async function sendShareChat() {
    let new_message = {
      friend_user_id: selectedMatch,
      type: "shareUser",
      body: '{ "message": "I found a new match for you!", "userId": ' + shareUser.id + '}',
    };
    sendMessage(new_message);
    onClose();
  }


  return (
    <>

      <IonPage className='chat-modal-page'>

        <IonHeader className="no-border">
          <IonToolbar className="toolbar-no-border bg-light">
            <IonTitle>Share profile</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={onClose}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="scouting-invitation-page bg-light">

          <IonList className="list-custom">
            <IonItem className='top-note' lines="none">
              <IonNote>Share this profile with the selected user</IonNote>
            </IonItem>
          </IonList>


          <div className="message-list">

            {matchesToShow === false ? getLoadingIndicator() : (matchesToShow.length === 0 ? getNoResultsIndicator() : matchesToShow.map((match: any, i: number) => {

              let chat_avatar = <RandomAvatar customSize={48} src={match.profile_image_url} />;

              return <div key={i} /*className={getClassName(match.id)}*/ className={match.selected ? "chat-card active" : "chat-card"} onClick={() => { toggleUser(match.id) }}>

                {(match.unseen_messages > 0) && <span className='new-chat-dot'></span>}

                <IonRow className="ion-align-items-center"  >
                  <IonCol size="auto">
                    {chat_avatar}
                  </IonCol>
                  <IonCol className="message-item-text">
                    <div>
                      <div className="user-type">
                        {(!match.current_role) && <span>CHAT</span>}
                        {(match.current_role === "mentee") && <span >MENTEE</span>}
                        {match.current_role === "mentor" && <span >MENTOR</span>}
                      </div>
                      <div className="user-name">{match.first_name} ∙ {match.age}</div>
                    </div>
                  </IonCol>
                </IonRow>

              </div>

            }))

            }

          </div>

          {selectedMatch != "" &&
            <IonRow className="send-button">
              <div>
                <p className="margin_auto cbutton_red pointer" onClick={() => { sendShareChat(); }}>SHARE</p>
              </div>
            </IonRow>
          }

        </IonContent>

      </IonPage>


    </>

  );

};

ChatsShareModal.defaultProps = {

}

export default ChatsShareModal;
