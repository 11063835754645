import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonTitle
} from "@ionic/react";
import {
  arrowDown,
  checkmarkOutline,
  briefcaseOutline,
  locationOutline,
  musicalNote,
  play,
  closeSharp,
  star,
  heartSharp,
  chevronBack,
  chatbubble
} from "ionicons/icons";
import ProfileImageSlides from "../../components/ProfileImageSlides/ProfileImageSlides";
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import ServiceTitle from '../../components/ServiceTitle/ServiceTitle';
// import SpotifyHighlights from "../../components/SpotifyHighlights/SpotifyHighlights";
import "./MentorProfile.scss";
import { getUser, getServices, getNoResultsIndicator, getActiveInterestsBadgesForProfile } from '../../apis/courtli/courtli';


type Props = {
  user: any;
  history: any;
  onClose: () => void;
  handleClickLike?: () => void;
  handleClickNope?: () => void;
};

const MentorProfile: React.FC<Props> = ({ user, onClose, handleClickLike, handleClickNope, history }) => {


  const [badges, setBadges] = useState<any>("None");
  const [likeButtonsEnabled, setLikeButtonsEnabled] = useState<boolean>(false);

  const [services, setServices] = useState<any>([]);
  // console.log("MentorProfile user", user);



  useEffect(() => {
    // console.log("MentorProfile useEffect() user", user);
    // console.log("MentorProfile useEffect() handleClickNope", handleClickNope);
    // console.log("MentorProfile useEffect() handleClickLike", handleClickLike);
    fetchData();

  }, []);

  function generateBadges() {

    let skills = ["Coaching", "Love advice", "Happiness", "Dating advice", "Jewish"];
    return skills.map(line => {
      return <IonButton key={line} fill="outline" shape="round" color="medium" size="small">
        {line}
      </IonButton>
    })
  }
  async function fetchData() {
    // await getUser();
    let badges_new = generateBadges();
    //console.log("MentorProfile badges_new", badges_new);
    setBadges(badges_new);

    if (handleClickLike) {
      setLikeButtonsEnabled(true);
    }

    fetchServices();
  }


  async function fetchServices() {

    let services_new = await getServices({
      // type: "courses"
      search: {
        user_id: user.id
      }
    });
    //console.log("MentorProfile services_new", services_new);
    setServices(services_new);
  }

  function handleClickWrapper(type: string) {
    console.log("MentorProfile handleClickWrapper() type", type);
    if (handleClickLike && handleClickNope) {

      if (type === "LIKE") {
        onClose();
        handleClickLike();
      } else {
        onClose();
        handleClickNope();
      }
    }
  }

  function openService(id: any) {
    console.log("MentorProfile openService() id", id);
    history.push("/service/" + id);
  }

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (
    <>



      <IonHeader className="no-border">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">
          <IonButtons slot="start">
            <IonButton onClick={onClose} >
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">
            <div className="profile-title">
              <span className="profile-user-name t2">{user.first_name || user.name}</span>
              <span className="profile-user-age"> {user.age}</span>
              <span className="icon-verified">
                <IonIcon icon={checkmarkOutline} />
              </span>
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>


      <IonContent className="profile-page">
        <div className="profile-header">
          <ProfileImageSlides images={user.images} />

          <IonFab vertical="bottom" horizontal="end" edge slot="fixed">
            <IonButton
              color="white"
              className="button-custom button-icon button-sm button-brand"
              onClick={() => { history.push("/chat/" + user.id); onClose() }}
            >
              <IonIcon icon={chatbubble} slot="icon-only" />
            </IonButton>
          </IonFab>
        </div>

        <div className="profile-info border-bottom">

          <div className="profile-user-info">
            <div className="info-item">
              <IonIcon icon={briefcaseOutline} />
              {user.job_title}
              {/* { getGenderDisplay(user.extra_data?.my_gender) } */}
            </div>

            {(user.location_description && user.location_description !== "Location unknown") &&
              <div className="info-item">
                <IonIcon icon={locationOutline} />
                {user.location_description} <img src={user.location_icon} height="12" className="margin_left_5px" />
                {/* {user.distance || "Distance unknown"} */}
              </div>
            }
          </div>

          <div className="passion-list">
            {badges}
          </div>
        </div>

        {/* <div className="margin_left_16px margin_top_30px width_90p">
            <div className="t3 semibold">Description</div>
            <div className="t4">{user.about_me}</div>
        </div> */}
        <div className="profile-intro border-bottom margin_top_20px">
          <div className="t2 semibold">About me</div>
          <div>{user.about_me}</div>
        </div>

        <div className="profile-intro border-bottom margin_top_20px">
          <div className="t2 semibold">Services</div>
          <div>
            {
              services.length === 0 ? getNoResultsIndicator() :
                services.map((service: any) => {

                  let match_count = Math.round(Math.random() * 5 + 1);

                  /// DATA HAS BEEN JOINED WITH USER SERVER SIDE
                  service.user = {
                    id: service.user_id,
                    first_name: service.first_name,
                    age: service.age,
                    profile_image_url: service.profile_image_url,
                    last_online_at: service.last_online_at
                  };

                  return <div key={service.id} className="width_100p margin_top_20px" onClick={() => { openService(service.id) }}>
                    <ServiceCard service={service} history={history} isEditCard={false} />
                  </div>
                })
            }
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />



      </IonContent>
    </>
  );
};

MentorProfile.defaultProps = {};

export default MentorProfile;
