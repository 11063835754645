/**
  Random Avatar for Ionic 6 React
  v1.0.0 (27/04/2022)
  Ion Wizard (https://ionwizard.gumroad.com)
*/

import React from 'react';
import {

} from '@ionic/react';
import {

} from 'ionicons/icons';
import USERS from './avatars';
import './RandomAvatar.scss';
import placeholder from '../../assets/img/user_placeholder.png';


// import * as AvatarGenerator from 'avatar-generator';
// const AvatarGenerator = require('avatnpar-generator')
// import * as path from 'path';
// var path = require('path');

type Props = {
  size?: string,
  customSize?: number,
  src?: string
}

// async function generateRandomImage(){
//   const avatar = new AvatarGenerator({
//     //All settings are optional.
//     parts: ['background', 'face', 'clothes', 'head', 'hair', 'eye', 'mouth'], //order in which sprites should be combined
//     partsLocation: path.join(__dirname, '../img'), // path to sprites
//     imageExtension: '.png' // sprite file extension
//   })
//   const variant = 'female' // By default 'male' and 'female' supported
//   const image = await avatar.generate('email@example.com', variant)
//   // Now `image` contains sharp image pipeline http://sharp.pixelplumbing.com/en/stable/api-output/
//   // you can write it to file
//   image
//     .png()
//   // or reszie
//   let image_file = await image
//     .resize(300,300)
//     .png()
// }
const RandomAvatar: React.FC<Props> = ({ size, customSize, src }) => {

  let imageUrl: string;
  if (!src || src === "random") {
    imageUrl = placeholder;
  } else {
    imageUrl = src
  }
  const styles = {
    backgroundImage: `url(${imageUrl})`,
  } as React.CSSProperties;
  let classes = 'avatar';

  if (size) {
    classes = `${classes} avatar-${size}`;
  }

  if (customSize) {
    styles.width = customSize;
    styles.height = customSize;
  }

  return (
    <div className="random-avatar">
      <div
        className={classes}
        style={styles}
      />
    </div>

  );
};

RandomAvatar.defaultProps = {

}

export default RandomAvatar;
