// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonItem,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonModal,
  IonLoading,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonList,
  IonListHeader,
  useIonAlert
} from "@ionic/react";
import {
  star,
  chevronBack,
  arrowForward,
  checkmarkDone
} from "ionicons/icons";
import MentorAvatar from '../../components/MentorAvatar/MentorAvatar';
import Reviews from '../../pages/Reviews/Reviews';
import "./ServiceEdit.scss";

//MENTOR
import {
  courtli,
  getServices,
  uploadImages,
  goBack,
  saveService,
  getPriceTypeDisplay,
  getUser,
  deleteService,
  decodeHtml,
  getMentorLevel
} from '../../apis/courtli/courtli';

type Props = {
  history: any;
};

const ServiceEdit: React.FC<Props> = ({ history }) => {


  const [presentAlert] = useIonAlert();
  const [price, setPrice] = useState('');
  const [priceType, setPriceType] = useState('one_time');
  const [priceModalIsOpen, setPriceModalIsOpen] = useState(false);
  const [publishModalIsOpen, setPublishModalIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Uploading image...");

  const [isReviewsOpen, setIsReviewsOpen] = useState<boolean>(false);
  let params = useParams<{ service_id: string }>();
  let service_id = params.service_id;
  // console.log("Service service_id", service_id);

  let [service, setService] = useState<any>({
    id: service_id,
    name: "Your service name",
    description: "Your service description",
    price: "10",
    type: "coaching",
    price_type: "one_time",
    rating_count: 0,
    rating_stars: 0,
    title_image_url: "https://courtli.s3.us-east-1.amazonaws.com/static/default_profile_image2.jpg",
  });


  useEffect(() => {

    fetchData();

  }, []);

  async function fetchData() {
    // await getUser();
    if (!service_id || service_id === "new") {
      let user_new = await getUser({ user_cache: true });
      let service_new = JSON.parse(JSON.stringify(service));
      service_new.user = user_new;
      service_new.about_me = user_new.about_me;
      setService(service_new);
      console.log("ServiceEdit service_new", service_new);
      return;
    }
    let services_new = await getServices({ id: service_id });
    // console.log("Services fetchData() services_new", services_new);
    if (services_new[0]) {
      let service_new = services_new[0];
      service_new.user = {
        id: service_new.user_id,
        first_name: service_new.first_name,
        age: service_new.age,
        profile_image_url: service_new.profile_image_url,
        last_online_at: service_new.last_online_at,
        level: service_new.level
      };
      setService(service_new);
    } else {
      alert("Sorry, this service is not found.");
    }
  }

  const handlePriceChange = (event: any) => {
    let service_new = JSON.parse(JSON.stringify(service));
    service_new["price"] = event.target.value;
    console.log("updateValue() service_new", service_new);
    setService(service_new);
  }

  const handlePriceTypeChange = (event: any) => {
    let service_new = JSON.parse(JSON.stringify(service));
    service_new["price_type"] = event.target.value;
    console.log("updateValue() service_new", service_new);
    setService(service_new);
  }

  async function saveServiceToServer(service: any) {
    let response = await saveService(service);
    if (response.response_code === 1) {
      alert("Your service has been saved.");
      goBack(history, { refresh: true });
    } else {
      alert("Sorry, there was an error saving your service. Please try again later.");
    }
  }
  function updateValue(event: any, label: string) {
    let service_new = JSON.parse(JSON.stringify(service));
    let html = event.target.innerHTML;
    /// CLEANUP
    html = html.replace(/&amp;nbsp; &amp;nbsp;/g, "\n\r");
    html = html.replace(/<br>/g, "\n\r");
    service_new[label] = html;
    console.log("updateValue() service_new", service_new);
    setService(service_new);
  }

  /// UPLOAD IMAGE  
  function toggleFileInput() {
    document.getElementById("user_image_input")?.click();
    return true;
  }
  async function triggerImageUpload(event: any) {
    // console.log("triggerImageUpload() event", event);
    // Get a reference to the file that has just been added to the input
    const photos = event.target.files;
    const photo = photos[0];
    // Create a form data object using the FormData API
    let formData = new FormData();
    formData.append("token", courtli.token.token);
    // Add the file that was just added to the form data
    formData.append("photo", photo, photo.name);
    // POST formData to server using Fetch API
    // uploadImage(formData);

    toggleLoader("Uploading images...");

    let response = await uploadImages(event, photos);
    console.log("uploadImages() response", response);
    toggleLoader("");

    if (response) {
      let image_url = "https://courtli.s3.us-east-1.amazonaws.com/" + response.image_url;
      let service_new = JSON.parse(JSON.stringify(service));
      service_new.title_image_url = image_url;
      setService(service_new);
    } else {
      alert("Failed to upload your image - please try later...");
    }



    window.gtag("event", "image_upload", {
      event_category: "actions",
      event_label: "image_upload"
    });
  }

  function toggleLoader(message: string) {
    if (message !== "") {
      setIsLoading(true);
      setLoadingMessage(message);
    } else {
      setIsLoading(false);
    }
  }

  function publishAndRedirect(state: any) {
    let service_new = JSON.parse(JSON.stringify(service));
    service_new.published_by_mentor = state ? 1 : 0;
    console.log("publishAndRedirect() service_new", service_new);
    setService(service_new);

    saveServiceToServer(service_new);

  }
  const handleDeleteService = () => {

    presentAlert({
      header: 'Are you sure you want to delete this service? THIS ACTION WILL BE PERMANENT!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            alert("Hjuuh - close call. Service deletion stopped.");
            onClose();
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () => {
            console.log("DELETING SERVICE FOR REAL");
            let res = await deleteService(service_id);
            if (res.response_code === 1) {
              alert("Your service has been deleted.");
              goBack(history, { refresh: true });
            } else {
              alert("There was an error: " + res.info);
            }
          },
        },
      ]
      // onDidDismiss: (e: CustomEvent) => { 
      //   console.log("DISMISSED");
      //   // alert("Hjuuh - close call. Account deletion stopped.")
      // }
    })

  }
  return (
    <IonPage className="service-edit-page">

      <IonLoading
        isOpen={isLoading}
        message={loadingMessage}
        duration={5000}
      />

      <IonHeader className="no-border">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">
          <IonButtons slot="start">
            <IonBackButton text={""} icon={chevronBack} defaultHref="/tabs/services" />
          </IonButtons>
          <IonTitle className="ion-text-center">Edit Service</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="profile-page">

        <div className="safe-area-bottom">

          {/* HIDDEN IMAGE UPLOAD */}
          <form action="/api/v1/document_editor/upload" className="dropzone upload_document_form display_none" method="post" encType="multipart/form-data" id="user_image_form">
            <input name="review_id" type="hidden" id="document_editor_upload_review_id" />
            <div className="fallback">
              <input name="myFile" id="user_image_input" type="file" onChange={(event) => { triggerImageUpload(event) }} />
              <input type="submit" value="Submit" />
            </div>
          </form>

          {/* IMAGE */}
          <div>
            <img src={service.title_image_url} className="cservice_title_image" />
            <span className="edit_image_button t4 pointer" onClick={() => { toggleFileInput() }} >Change</span>
          </div>

          {/* TITLE */}
          <div className="service-title" contentEditable="true" onBlur={(event) => { updateValue(event, "name") }}>{decodeHtml(service.name)}</div>

          {/* DESCRIPTION */}
          {service && <div className="service-cost" onClick={() => { setPriceModalIsOpen(true) }}>${Number(service.price).toFixed(2)} {getPriceTypeDisplay(service.price_type)}</div>}

          {/* REVIEWS */}
          <div className="reviews-button"
            onClick={() => setIsReviewsOpen(true)}>
            <span className="semibold"><IonIcon className='star-icon' icon={star} />{Math.round(service.rating_stars * 10) / 10}</span>
            <div className="red-button">reviews <IonIcon icon={arrowForward} /></div>
          </div>

          {/* SERVICE DESCRIPTION */}
          <div className="section-title">Description</div>
          <div className="section-description" contentEditable="true" onBlur={(event) => { updateValue(event, "description") }}>{decodeHtml(service.description)}</div>


          {/* SERVICE MENTOR */}
          <div className="section-title">About seller</div>
          {service.user &&
            <div onClick={() => { history.push("/mentor/" + service.user.id) }}>
              <IonRow class='mentor-container'>
                <div className="ctiny_avatar">
                  <img src={service.user.profile_image_url} />
                </div>
                <IonCol class='mentor-info'>
                  <div className='mentor-name'>{service.user.first_name} <IonIcon icon={checkmarkDone} /></div>
                  <div className='mentor-level'>{getMentorLevel(service.user)} mentor,</div>
                </IonCol>
              </IonRow>
              <div className='mentor-description'>{decodeHtml(service.about_me)}</div>
            </div>
          }

          {/* SIMILAR SERVICES MENTOR */}
          <div className="similar-services">
            <div className="section-title">Similar services from mentor</div>
            <div className="empty-space"></div>
            <div className="red-button">view all <IonIcon icon={arrowForward} /></div>
          </div>

        </div>

        {/* BUTTON */}
        <IonRow class="big_action_button">
          <div className="flex">
            <div className="cbutton_red margin_auto pointer cbutton_95p" onClick={() => (setPublishModalIsOpen(true))}>Update</div>
          </div>
        </IonRow>

      </IonContent>

      <IonModal isOpen={priceModalIsOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Edit Price</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => { setPriceModalIsOpen(false); }}>Done</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {/* <IonList className="list-custom">
          <IonListHeader>
            <IonLabel>Price Type</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonSelect placeholder="Choose services"
                value={service.price_type}
                onIonChange={handlePriceTypeChange} 
            >                 
              <IonSelectOption value="one_time">One Time</IonSelectOption>
              <IonSelectOption value="per_week">Per Week</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList> */}
        <IonList className="list-custom">
          <IonListHeader>
            <IonLabel>Price USD</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonInput type="number" value={service.price} onIonChange={handlePriceChange}></IonInput>
          </IonItem>
        </IonList>

        <IonButton onClick={() => { setPriceModalIsOpen(false) }}>Done</IonButton>
      </IonModal>


      {/* PUBLISH MODAL */}
      <IonModal isOpen={publishModalIsOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Publish Service</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => { setPublishModalIsOpen(false); }}>Done</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="settings-page bg-light">

          <IonRow>
            <div className="megaemoji margin_auto margin_top_80px position_relative z100">✈️</div>
          </IonRow>
          <IonRow>
            <div className="margin_auto margin_top_40px t1 color_dark_gray position_relative z100">
              Ready for Liftoff?
            </div>
          </IonRow>
          <IonRow>
            <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray position_relative z100 width_90p">
              Make your service visible to other Courtli users!
              Before it is published, only you can see it.
              It will require approval from our team.
              Courtli will inform you via chat once its approved.
            </div>
          </IonRow>
          <IonRow class="wizard_action_row padding_20px_horizontal width_100p z100">
            <div className="flex wrap space_evenly">
              <div className="margin_auto cbutton_red pointer margin_top_10px" onClick={() => (publishAndRedirect(1))}>Publish</div>
              <div className="margin_auto cbutton_red pointer margin_top_10px" onClick={() => (publishAndRedirect(0))}>Unpublish</div>
              <br /><div className="margin_auto cbutton_red pointer margin_top_10px" onClick={() => (handleDeleteService())}>Delete</div>
            </div>
            {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
          </IonRow>



        </IonContent>
      </IonModal>

    </IonPage >
  );
};

ServiceEdit.defaultProps = {};

export default ServiceEdit;
