
import React, { Component, useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonText,
  IonList,
  IonListHeader,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonRow

} from '@ionic/react';

import {
  courtli,
  courtliHealthCheckWithRedirect,
  goBack,
  getUser,
  changeUserExtraDataField,
  getUserInterests,
  resetCache,
  getOnboardingNextStep
} from '../../apis/courtli/courtli';

/// STYLESHEETS
import { chevronBack } from 'ionicons/icons';
import './Wizards.scss';
import '../Interests/Interests.scss';




/// CONSTANTS


type Props = {
  history: any;
  onClose: () => void;
  isModal: boolean
};

const InterestsWizard: React.FC<Props> = ({ history, onClose, isModal }) => {


  let [user, setUser] = useState<any>(null);
  const [badges, setBadges] = useState<any>("None");
  const [activeInterestsCount, setActiveInterestsCount] = useState<any>(0);

  /// VARIABLES
  let [nextStep, setNextStep] = useState<any>({
    title: "START NOW",
    wizard: "/profile"
  });
  /// FUNCTION DEFINITIONS


  /// EXECUTION
  useEffect(() => {
    fetchData();
  }, [])
  async function fetchData() {

    courtliHealthCheckWithRedirect(history);

    let user_new = await getUser({ use_cache: false });
    setUser(user_new);
    user = user_new;
    let badges_new = await getMyInterestsBadges(user_new);
    updateActiveInterestsCount(user_new.interests);
    setBadges(badges_new);
  }

  async function reloadFunction() {
    fetchData();
  }


  async function updateActiveInterestsCount(new_interests: any) {
    if (new_interests) {
      let a = new_interests.filter((line: any) => line.active);
      a = a.length;
      //console.log("Interests updateActiveInterestsCount() a", a);
      setActiveInterestsCount(a);
      return a;
    }
  }
  async function getMyInterestsBadges(usera: any) {
    let interests = await getUserInterests(usera);

    //   console.log("Interests getMyInterestsBadges() interests", interests);
    let badges = interests.map((item: any, i: number) => {
      return <IonButton className={item.active ? 'active' : 'inactive'}
        key={item.label}
        onClick={() => { toggleInterestAndReload(i) }}
        shape="round"
        fill="outline"
        size="small"
      >

        {item.label}
      </IonButton>
    });
    return badges;
  }
  async function toggleInterestAndReload(i: number) {
    /// DONT LET IT EXCEED MAX
    let new_interests = await toggleInterest(i);
    if (new_interests) {
      updateActiveInterestsCount(new_interests);
      resetCache();
      reloadFunction();
    }
  }
  async function toggleInterest(i: number) {

    let interests = await getUserInterests(user);
    let state = interests[i].active;
    let active_interests = interests.filter((line: any) => line.active);
    console.log("toggleInterestAndReload() active_interests", active_interests);
    console.log("toggleInterestAndReload() state", state);

    if (active_interests.length > 6 && state === false) {
      alert("You are not allowed to select more than 7 interests");
      return false;
    }

    if (interests[i].active === true) {
      interests[i].active = false;
    } else {
      interests[i].active = true;
    }
    let result = await changeUserExtraDataField("interests", interests);
    user.interests = interests;
    console.log("toggleInterest() result", result);
    return interests;
  }
  async function checkIfYouCanClose() {
    if (!isModal) {
      let a = await updateActiveInterestsCount(user.interests);
      if (a > 7) {
        alert("You must not have more than 7 interests");
      } else if (a < 3) {
        alert("You must have at least 3 interests");
      } else {
        /// CHECK IF THERE IS MORE ONBOARDING TO BE DONE
        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
        let next_step = getOnboardingNextStep(await getUser({ use_cache: false }));
        console.log("saveBirthdayAndProceed() changeUserField() next_step", next_step);
        history.push(next_step.wizard);
      }
    } else {
      let a = await updateActiveInterestsCount(user.interests);
      if (a > 7) {
        alert("You must not have more than 7 interests");
      } else if (a < 3) {
        alert("You must have at least 3 interests");
      } else {
        onClose();
      }

    }
  }

  return (
    <IonPage className="wizard-page">

      {/* 
      <IonHeader className="header-custom">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">
          <IonButtons slot="start">
            <IonButton onClick={() => { checkIfYouCanClose() }} >
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">Interests</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              hidden={false}
              onClick={() => { checkIfYouCanClose() }}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      */}

      <IonHeader className="no-border">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">
          <IonButtons slot="start">
            {!isModal &&
              <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
            }
            {isModal &&
              <IonButton onClick={() => { onClose() }}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>


      <IonContent className="ion-padding" fullscreen={true}>

        <IonRow>
          <div className="top-emoji">🎨</div>
        </IonRow>
        <IonRow>
          <div className="top-title">
            Interests
          </div>
        </IonRow>

        <IonRow>
          <div className="top-description">
            Highlight 3 or more interests. Up to 7 in total.
          </div>
        </IonRow>

        {user &&

          <div className="interests-page">
            <IonList className="list-custom interests-list">

              {/*
              <IonListHeader>
                <IonLabel>Interests</IonLabel>
                <IonText color="medium" className="tag-count">({activeInterestsCount}/5)</IonText>
              </IonListHeader>
              */}

              <div className="ion-padding badges">
                {badges}
              </div>
            </IonList>
          </div>
        }

        <IonRow class="wizard_action_row">
          <div className="flex">
            <div className="margin_auto  cbutton_red pointer" onClick={() => { checkIfYouCanClose() }}>CONTINUE</div>
          </div>
        </IonRow>

      </IonContent>


    </IonPage>
  )
}

InterestsWizard.defaultProps = {};

export default InterestsWizard;