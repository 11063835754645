
import React from "react";
import './RemoveUserModal.scss';
import RandomAvatar from "../RandomAvatar/RandomAvatar";

type Props = {
    setIsRemoveUser?: any,
    user?: any,
}

const RemoveUserModal: React.FC<Props> = ({ setIsRemoveUser, user }) => {


    function capitalizeFirst(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    return (
        <>
            <div className="background" onClick={() => setIsRemoveUser(false)} />
            <div className="modal-container">
                <div className="modal">

                    <RandomAvatar customSize={125} src={user.profile_image_url} />

                    <div className="modal-content">
                        {user.first_name}
                    </div>

                    <div className="user-level">
                        Level {user.level} {capitalizeFirst(user.current_role)}
                    </div>

                    <div className="modal-content">
                        Are you sure you want to delete this user from your friends list?
                    </div>
                    <div className="modal-actions">
                        <button className="cancelBtn" onClick={() => setIsRemoveUser(false)}>
                            Cancel
                        </button>
                        <button className="deleteBtn" onClick={() => setIsRemoveUser(false)}>
                            Delete
                        </button>
                    </div>

                    <div className="reportBtn" onClick={() => setIsRemoveUser(false)}>
                        Delete & report this user
                    </div>
                </div>
            </div>
        </>
    );
};

export default RemoveUserModal;