// @ts-nocheck
import React, { Component, useEffect, useState, useRef } from 'react';
import {
    IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
    IonItem,
    IonLabel,
    IonSpinner,
    IonBackButton,
    IonInput,
    IonButtons,

    //LIST
    IonList, IonSelect, IonSelectOption,

    //ICONS
    IonIcon,

    IonPicker

} from '@ionic/react';

import {
    courtli,
    courtliHealthCheckWithRedirect,
    getOnboardingNextStep,
    initOnboarding,
    changeUserField,
    changeUserFieldAlsoExtraData,
    goBack,
    getUser,
    getAge,
    goToUrlAndReload
} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack, chevronForward } from 'ionicons/icons';
import './Wizards.scss';
import { last } from 'rxjs';


/// CONSTANTS
type Props = {
    history: any;
    user: any;
    onClose: () => void;
};

const NameWizard: React.FC<Props> = ({ history, user, onClose }) => {

    /// EXECUTION
    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        let user = await getUser({ use_cache: false });
        console.log("NameWizard fetchData() user", user);

        if (user.first_name) {
            setName(user.first_name);
        }

        if (user.last_name) {
            setLastName(user.last_name);
        }

        if (user.birthday_at) {
            setBirthday(user.birthday_at);
        }

        if (user.extra_data.heightFt != null) {
            setHeightFt(user.extra_data.heightFt);
        }

        if (user.extra_data.heightIn != null) {
            setHeightFt(user.extra_data.heightIn);
        }

        if (user.email) {
            setEmail(user.email);
        }

        if (user.is_right_handed != null) {
            let rightHanded = user.is_right_handed;
            if (rightHanded == 1) {
                setHand('Right');
            }
            if (rightHanded == 0) {
                setHand('Left');
            }
        }

        if (user.extra_data.my_gender) {
            let gender = "";
            let selectedGender = user.extra_data.my_gender;
            if (selectedGender == 'man') {
                gender = 'Male';
            }
            if (selectedGender == 'woman') {
                gender = 'Female';
            }
            setGender(gender);
        }

        if (user.extra_data.looking_for) {
            let gender = "";
            let selectedGender = user.extra_data.looking_for;
            if (selectedGender == 'man') {
                gender = 'Male';
            }
            if (selectedGender == 'woman') {
                gender = 'Female';
            }
            setGenderInterested(gender);
        }

        courtliHealthCheckWithRedirect(history);
        // initOnboarding(setNextStep);
    }

    /// VARIABLES
    let [name, setName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");

    //HEIGHT
    const [heightFt, setHeightFt] = useState("");
    const [heightIn, setHeightIn] = useState("");

    //BIRTHDAY
    let [birthday, setBirthday] = useState(946723843000);
    let [age, setAge] = useState(0);
    function calculateAgeAndSetBirthday(birthday_date: string) {
        if (!birthday_date || birthday_date.length === 0) {
            return;
        }
        let new_birthday = new Date(birthday_date);
        setBirthday(new_birthday.getTime());

        let new_age = getAge(new_birthday.toString());
        setAge(new_age);
    }


    //HAND
    const [hand, setHand] = useState("SELECT HAND YOU USE WHILE USING APP");
    const handElementRef = useRef<HTMLInputElement>(null);
    async function openSelectHand() {
        if (handElementRef.current != null) {
            handElementRef.current.click();
        }
    }

    //GENDER
    const [gender, setGender] = useState("SELECT GENDER");
    const genderElementRef = useRef<HTMLInputElement>(null);
    async function openSelectGender() {
        if (genderElementRef.current != null) {
            genderElementRef.current.click();
        }
    }

    //GENDER INTERESTED
    const [genderInterested, setGenderInterested] = useState("SELECT GENDER");
    const genderInterestedElementRef = useRef<HTMLInputElement>(null);
    async function openSelectGenderInterested() {
        if (genderInterestedElementRef.current != null) {
            genderInterestedElementRef.current.click();
        }
    }

    async function saveName() {

        if (name.length > 1 && lastName.length > 1) {
            let result = await changeUserField("first_name", name);
            let result2 = await changeUserField("last_name", lastName);
            if (result.response_code === 1) {
                goBack(history, { refresh: true });
            } else {
                alert("We could not save your name - make sure there are only letters in it.");
            }
        } else {
            alert("Your name must be at least 2 characters long");
        }

    }

    async function saveAllFields() {

        if (name.length < 1) {
            alert("Please enter your first name");
            return;
        } else if (lastName.length < 1) {
            alert("Please enter your last name");
            return;
        } else if (email.length < 1) {
            alert("Please enter your email");
            return;
        } else if (birthday.length < 1) {
            alert("Please enter your birthday");
            return;
        } else if (hand !== "Right" && hand != "Left") {
            alert("Please select your handedness");
            return;
        } else if (gender != "Male" && gender != "Female") {
            alert("Please select your gender");
            return;
        } else if (genderInterested !== "Male" && genderInterested != "Female") {
            alert("Please select the gender you are interested in");
            return;
        } else if (heightFt == "") {
            alert("Please select your height");
            return;
        }

        /// SAVE ALL FIELDS TO DB
        let result = await changeUserField("first_name", name);
        let result2 = await changeUserField("last_name", lastName);
        let result3 = await changeUserField("email", email);
        let result4 = await changeUserField("birthday_at", birthday);

        let is_right_handed = 1;
        if (hand === "Left") {
            is_right_handed = 0;
        }
        let result5 = await changeUserField("is_right_handed", is_right_handed);

        //GENDER
        let selectedGender = "";
        if (gender == 'Male') {
            selectedGender = 'man';
        }
        if (gender == 'Female') {
            selectedGender = 'woman';
        }
        let result6 = await changeUserFieldAlsoExtraData("extra_data.my_gender", selectedGender);

        //GENDER INTERESTED
        let selectedGenderInterested = "";
        if (genderInterested == 'Male') {
            selectedGenderInterested = 'man';
        }
        if (genderInterested == 'Female') {
            selectedGenderInterested = 'woman';
        }
        let result7 = await changeUserFieldAlsoExtraData("extra_data.looking_for", selectedGenderInterested);

        //HEIGHT
        let result8 = await changeUserFieldAlsoExtraData("extra_data.heightFt", heightFt);
        let result9 = await changeUserFieldAlsoExtraData("extra_data.heightIn", heightIn);


        /// CONSOLE LOG ALL RESULTS
        console.log("saveAllFields() results", [
            result,
            result2,
            result3,
            result4,
            result5,
            result6,
            result7,
            result8,
            result9
        ]);

        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
        let user = await getUser({ use_cache: false });
        console.log("saveAllFields() user", user);
        let next_step = getOnboardingNextStep(user);
        console.log("saveAllFields() next_step", next_step);
        history.push(next_step.wizard);

        /// NO NEED TO RELOAD
        // goToUrlAndReload(history, next_step.wizard);

    }



    return (
        <IonPage className="wizard-page">

            <IonHeader className="no-border">
                <IonToolbar className="user-profile-toolbar toolbar-no-border ">
                    <IonButtons slot="start">
                        <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
                    </IonButtons>
                    {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
                    <IonButtons slot="end">
                        <IonButton></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent className="ion-padding" fullscreen={true}>
                <IonRow>
                    <div className="top-emoji">✌️</div>
                </IonRow>
                <IonRow>
                    <div className="top-title">
                        General
                    </div>
                </IonRow>

                <IonRow>
                    <div className="inputs-container ">

                        <IonInput
                            class="cinput"
                            placeholder="ENTER YOUR FIRST NAME"
                            value={name}
                            onIonChange={(e: any) => {
                                setName(e.target.value.toUpperCase());
                            }}
                        >
                            <div className="clabel">
                                FIRST NAME
                            </div>
                        </IonInput>

                        <IonInput
                            class="cinput"
                            placeholder="ENTER YOUR LAST NAME"
                            value={lastName}
                            onIonChange={(e: any) => {
                                setLastName(e.target.value.toUpperCase());
                            }}
                        >
                            <div className="clabel">
                                LAST NAME
                            </div>
                        </IonInput>


                        <div className={"select-button height"}>
                            <div className="title">HEIGHT</div>

                            <div className='height-input-container'>
                                <IonList>
                                    <IonItem>
                                        <IonSelect value={heightFt} aria-label="Select" interface="popover" placeholder="0" onIonChange={(e) => setHeightFt(e.detail.value)}>
                                            <IonSelectOption value="4">4</IonSelectOption>
                                            <IonSelectOption value="5">5</IonSelectOption>
                                            <IonSelectOption value="6">6</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonList>

                                <h5>FT</h5>

                                <IonList>
                                    <IonItem>
                                        <IonSelect value={heightIn} aria-label="Select" interface="popover" placeholder="0" onIonChange={(e) => setHeightIn(e.detail.value)}>
                                            <IonSelectOption value="0">0</IonSelectOption>
                                            <IonSelectOption value="1">1</IonSelectOption>
                                            <IonSelectOption value="2">2</IonSelectOption>
                                            <IonSelectOption value="3">3</IonSelectOption>
                                            <IonSelectOption value="4">4</IonSelectOption>
                                            <IonSelectOption value="5">5</IonSelectOption>
                                            <IonSelectOption value="6">6</IonSelectOption>
                                            <IonSelectOption value="7">7</IonSelectOption>
                                            <IonSelectOption value="8">8</IonSelectOption>
                                            <IonSelectOption value="9">9</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonList>

                                <h5>IN</h5>

                            </div>

                        </div>






                        <div className={"select-button"}>
                            <div className="title">BIRTHDAY</div>
                            <input
                                type="date"
                                className="cdate_input"
                                value={new Date(birthday).toISOString().slice(0, 10)}
                                onInput={(e: any) => calculateAgeAndSetBirthday(e.target.value)}
                            />
                        </div>


                        <IonInput
                            class="cinput"
                            placeholder="ENTER YOUR EMAIL"
                            value={email}
                            onIonChange={(e: any) => {
                                setEmail(e.target.value.toUpperCase());
                            }}
                        >
                            <div className="clabel">
                                YOUR E-MAIL
                            </div>
                        </IonInput>


                        <div className={"select-button"} onClick={() => (openSelectHand())}>
                            <div className="title">HANDENESS</div>
                            <div className="description">{hand.toUpperCase()}</div>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                        </div>

                        <IonList className='display_none'>
                            <IonItem >
                                <IonSelect placeholder="" ref={handElementRef} onIonChange={(e) => setHand(e.detail.value)}>
                                    <ion-label>Select</ion-label>
                                    <IonSelectOption value="Left">Left</IonSelectOption>
                                    <IonSelectOption value="Right">Right</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>


                        <div className={"select-button"} onClick={() => (openSelectGender())}>
                            <div className="title">GENDER</div>
                            <div className="description">{gender.toUpperCase()}</div>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                        </div>

                        <IonList className='display_none'>
                            <IonItem >
                                <IonSelect placeholder="" ref={genderElementRef} onIonChange={(e) => setGender(e.detail.value)}>
                                    <ion-label>Select</ion-label>
                                    <IonSelectOption value="Male">Male</IonSelectOption>
                                    <IonSelectOption value="Female">Female</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>


                        <div className={"select-button"} onClick={() => (openSelectGenderInterested())}>
                            <div className="title">GENDER INTERESTED</div>
                            <div className="description">{genderInterested.toUpperCase()}</div>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                        </div>

                        <IonList className='display_none'>
                            <IonItem >
                                <IonSelect placeholder="" ref={genderInterestedElementRef} onIonChange={(e) => setGenderInterested(e.detail.value)}>
                                    <ion-label>Select</ion-label>
                                    <IonSelectOption value="Male">Male</IonSelectOption>
                                    <IonSelectOption value="Female">Female</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>


                    </div>
                </IonRow>


                <IonRow class="wizard_action_row">
                    <div className="flex">
                        <div className="margin_auto  cbutton_red pointer" onClick={() => (saveAllFields())}>SAVE</div>
                    </div>
                </IonRow>

            </IonContent>
        </IonPage >
    )
}

NameWizard.defaultProps = {};

export default NameWizard;