
import { constants } from 'buffer';
import React from 'react';
import { useEffect } from 'react';
import "./HeartsAnimation.css";


type Props = {
    mode: string
};




const HeartsAnimation: React.FC<Props> = ({mode}) => {

    // console.log("HeartsAnimation mode", mode);

    let old_script = document.getElementById("hearts_animation_script");
    if(old_script){
        // console.log("HeartsAnimation found old script");
        old_script.remove();
    }
    let script_src = "/js/hearts_login.js";
    if(mode == "many_hearts"){
        script_src = "/js/hearts_many.js";
    }
    let script = document.createElement('script');
    script.src = script_src;
    script.async = true;
    script.id = "hearts_animation_script";

    document.body.appendChild(script);

    return (
        <canvas id="hearts_canvas"></canvas>
    );
  };
  
  HeartsAnimation.defaultProps = {
  
  }
  
  export default HeartsAnimation;
  