// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import {
  IonRow, IonCol, IonButton, IonIcon, IonModal
} from '@ionic/react';

import './ServiceCard.scss';
import { getMentorLevel } from '../../apis/courtli/courtli';


//PROFILE
import ProfileDiscover from '../../pages/ProfileDiscover/ProfileDiscover';
import MentorProfile from '../../pages/MentorProfile/MentorProfile';

//MENTOR
import MentorAvatar from '../MentorAvatar/MentorAvatar';

//ICONS
import { star, checkmarkDone, chevronForward } from 'ionicons/icons';

type Props = {
  service: any,
  history: any,
  isEditCard: boolean
}

const ServiceCard: React.FC<Props> = ({ service, history, isEditCard }) => {

  //IMAGE HEIGHT
  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  //INIT
  useEffect(() => {
    setTimeout(() => {
      setImageHeight(getImageHeight());
    }, 1);
  }, []);

  //HANDLE RESIZE
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setImageHeight(getImageHeight());
  }

  //CALC HEIGHT
  function getImageHeight() {
    var goldenRatio = 0.5;
    let imageRefWidth = imageRef.current.clientWidth;
    return Math.floor(imageRefWidth * goldenRatio);
  }

  //OPEN MENTOR PAGE
  function viewMentorProfile() {
    if (!isEditCard) {
      history.push("/mentor/" + service.user.id)
    }
  }

  //OPEN SERVICE PAGE
  function openService(id: any) {
    if (!isEditCard) {
      history.push("/service/" + id);
    }
  }

  //OPEN EDIT SERVICE PAGE
  function openEditService(id: any) {
    if (isEditCard) {
      history.push("/service/" + id + "/edit");
    }
  }

  //PROFILE
  const [isProfileOpen, setProfileOpen] = useState(false);
  function handleOpenProfile() {
    setProfileOpen(!isProfileOpen);
  }

  function handleToggleProfile() {
    setProfileOpen(!isProfileOpen);
  }

  return (
    <>
      <div className="service-card" onClick={() => { openEditService(service.id) }}>

        <IonRow class='mentor-container' onClick={() => { handleOpenProfile() }}>
          <div className="ctiny_avatar" >
            <img src={service.user.profile_image_url} />
          </div>
          <IonCol class='mentor-info'>
            <div className='mentor-name'>{service.user.first_name} <IonIcon icon={checkmarkDone} /></div>
            <div className='mentor-level'>{getMentorLevel(service.user)} mentor,</div>
            <IonIcon className='mentor-forward-icon' icon={chevronForward} />
            <div className='edit-label red' style={isEditCard ? { display: 'block' } : { display: 'none' }}>EDIT</div>
          </IonCol>
        </IonRow>

        <div className='card-service-container' onClick={() => { openService(service.id) }}>
          <img ref={imageRef} className='service-image' src={service?.title_image_url} style={{ height: imageHeight + "px" }} />
          <div className="card-caption">
            <IonRow>
              <IonCol className="caption-text">
                <div className="caption-title">
                  {service.name}
                </div>

                <div className='caption-bottom'>
                  <div className="caption-price">
                    $ {service.price}
                  </div>
                  <div className='space'>
                  </div>
                  <div className="caption-rating">
                    <IonIcon icon={star} /> {service.rating_stars}
                    <div className='caption-rating-count'>({service.rating_count})</div>
                  </div>
                </div>

              </IonCol>
            </IonRow>
          </div>
        </div>

      </div>


      <IonModal isOpen={isProfileOpen} swipeToClose transparent overScrollMode="never">

        {/* 
        {
          (current_role === "mentor") &&

          <MentorProfile
            user={currentProfile}
            onClose={this.handleToggleProfile}
            history={this.props.history}
            handleClickNope={this.handleClickNope}
            handleClickLike={this.handleClickLike}
          />
        }

        */}

        <ProfileDiscover
          userId={service.user.id}
          onClose={handleToggleProfile}
          history={history}
        />

      </IonModal>


    </>

  );
};

ServiceCard.defaultProps = {

}

export default ServiceCard;
