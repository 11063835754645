import React from "react";

import {
  IonContent,
  IonIcon,
  IonToolbar,
  IonInput,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { heart } from "ionicons/icons";
import LabelMatched from "../../components/LabelMatched/LabelMatched";
import "./MatchedModal.scss";

import match from '../../assets/img/match.png';

// import HeartsAnimation2 from '../../components/HeartsAnimation2/HeartsAnimation2';

import { useCallback } from "react";
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import RandomAvatar from "../../components/RandomAvatar/RandomAvatar";

type Props = {
  user: any,
  onClose: () => void,
  goToChatWithUser: (user_id: string) => void,
  onViewInfo?: () => void;
};

const MatchedModal: React.FC<Props> = ({ user, onClose, goToChatWithUser, onViewInfo }) => {

  if (!user) {
    user = {
      id: 1,
      profile_image_url: "assets/img/people/12.jpeg",
      name: "Emily",
      first_name: "Emily",
      age: "27",
      height: "5’6”",
      location_description: "San Antonio, Tx.",
      distance: "37 mi away",
      job: "COO at BeYourself Ltd.",
      last_online_at: new Date().getTime(),
    };
  }

  console.log("MatchedModal user", user);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    await container;
    // await console.log(container);
  }, []);


  //CHECK ONLINE
  function isOnline(lastConnection: number) {
    let time = new Date().getTime();
    if (time < lastConnection + 1 * 60 * 1000) {
      return true;
    }
    return false;
  }

  //PREVIEW
  const handleViewInfo = () => {
    if (onViewInfo) onViewInfo();
  };


  return (

    <IonContent force-overscroll="false" className="matched-modal-page">

      <Particles id="tsparticles" url="/js/confetti.json" init={particlesInit} loaded={particlesLoaded} />

      <div className="matched-container">

        <div style={{ backgroundImage: `url('${user.profile_image_url}')` }} className="background-img full-height z99" />
        <div className="matched-overlay">


          <div className="modal-container">

            <img className="match-img" src={match} />

            <div className="modal">

              <RandomAvatar customSize={85} src={user.profile_image_url} />

              <div className="modal-content">

                <div>
                  {user.age} • {user.first_name} • {user.height}
                </div>

                <div>
                  {user.location_description} • <span className="distance-label">{user.distance}</span>
                </div>

                <div>
                  {user.job}
                </div>

                <div>
                  {(isOnline(user.last_online_at)) ? <span className='status-online'>online</span> : <span className='status-offline'>offline</span>}
                </div>

              </div>

              <div className="action-buttons">
                <div className="chat-button" onClick={() => goToChatWithUser(user.id)}>
                  chat now
                </div>
                <div className="profile-button" onClick={handleViewInfo}>
                  view profile
                </div>
              </div>

            </div>
          </div>


        </div>


      </div>


      <div className="close-button" onClick={onClose}>
        keep swiping
      </div>

    </IonContent>
  );
};

MatchedModal.defaultProps = {};

export default MatchedModal;
