import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle
} from "@ionic/react";
import {
  arrowDown,
  checkmarkOutline,
  briefcaseOutline,
  locationOutline,
  musicalNote,
  play,
  closeSharp,
  star,
  heartSharp,
  chevronBack
} from "ionicons/icons";
import ProfileImageSlides from "../../components/ProfileImageSlides/ProfileImageSlides";
import "./Profile.scss";
import {
  getActiveInterestsBadgesForProfile,
  decodeHtml
} from '../../apis/courtli/courtli';


type Props = {
  user: any;
  onClose: () => void;
  handleClickLike?: () => void;
  handleClickNope?: () => void;
  likeButtonsEnabled?: boolean;
};

const Profile: React.FC<Props> = ({ user, onClose, handleClickLike, handleClickNope, likeButtonsEnabled }) => {


  const [badges, setBadges] = useState<any>("None");
  const [likeButtonsEnabled2, setLikeButtonsEnabled2] = useState<boolean>(false);




  useEffect(() => {
    //console.log("Profile useEffect() user", user);
    // console.log("Profile useEffect() handleClickNope", handleClickNope);
    // console.log("Profile useEffect() handleClickLike", handleClickLike);
    fetchData();

    async function fetchData() {
      // await getUser();
      let interests = await getActiveInterestsBadgesForProfile(user);
      let badges_new = interests.map((item: any, i: number) => {
        return <IonButton key={item.label} fill="outline" shape="round" color="medium" size="small">
          {item.label}
        </IonButton>
      });
      setBadges(badges_new);

      if (likeButtonsEnabled) {
        setLikeButtonsEnabled2(true);
      }
    }
  }, []);

  function handleClickWrapper(type: string) {
    console.log("Profile handleClickWrapper() type", type);
    if (handleClickLike && handleClickNope) {

      if (type === "LIKE") {
        onClose();
        handleClickLike();
      } else {
        onClose();
        handleClickNope();
      }
    }
  }

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (
    <>

      <IonHeader className="no-border">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">
          <IonButtons slot="start">
            <IonButton onClick={onClose} >
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">
            <div className="profile-title">
              <span className="profile-user-name t2">{user.first_name || user.name}</span>
              <span className="profile-user-age"> {user.age}</span>
              <span className="icon-verified">
                <IonIcon icon={checkmarkOutline} />
              </span>
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      {/* <IonContent className="profile-page">

       
      </IonContent> */}

      <IonContent className="profile-page">
        <div className="profile-header">
          <ProfileImageSlides images={user.images} />

          <IonFab vertical="bottom" horizontal="end" edge slot="fixed">
            <IonButton
              color="white"
              className="button-custom button-icon button-sm button-brand"
              onClick={onClose}
            >
              <IonIcon icon={arrowDown} slot="icon-only" />
            </IonButton>
          </IonFab>
        </div>

        <div className="profile-info border-bottom">


          <div className="profile-user-info">
            <div className="info-item">
              <IonIcon icon={briefcaseOutline} />
              {user.job_title}
            </div>

            {(user.location_description && user.location_description !== "Location unknown") &&
              <div className="info-item">
                <IonIcon icon={locationOutline} />
                {user.location_description} <img src={user.location_icon} height="12" className="margin_left_5px" />
                {/* {user.distance || "Distance unknown"} */}
              </div>
            }

          </div>

          <div className="passion-list">
            {badges}
          </div>
        </div>

        <div className="profile-intro border-bottom">
          <p>{decodeHtml(user.about_me)}</p>
        </div>

        {/* <div className="profile-anthem border-bottom">
          <div className="section-title">My Anthem</div>

          <IonRow className="grid-no-padding">
            <IonCol className="ion-align-self-center">
              <div>
                <strong>Fake ID</strong>
              </div>
              <div>
                <IonIcon icon={musicalNote} color="medium" />
                <IonText color="medium">Riton & Kah-Lo</IonText>
              </div>
            </IonCol>
            <IonCol size="auto">
              <div className="album-artwork">
                <div
                  className="album-cover background-img"
                  style={{ backgroundImage: `url(assets/img/album.png)` }}
                />
                <IonRow className="ion-justify-content-center ion-align-items-center">
                  <div className="album-artwork-overlay">
                    <IonButton
                      color="white"
                      className="button-custom button-icon text-primary"
                    >
                      <IonIcon slot="icon-only" icon={play} />
                    </IonButton>
                  </div>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </div> */}

        {/* <div className="profile-spotify border-bottom">
          <SpotifyHighlights />
        </div> */}

        {/* <div className="profile-share border-bottom">
          <IonButton fill="clear" expand="block" color="primary" size="small">
            <div className="button-label">
              <div>SHARE { user.first_name || user.name }'s PROFILE</div>
              <div>SEE WHAT A FRIEND THINKS</div>
            </div>
          </IonButton>
        </div> */}

        {/* <div className="profile-footer border-bottom">
          <IonButton fill="clear" expand="block" color="medium" size="small">
            <div className="button-label">REPORT THIS PROFILE</div>
          </IonButton>
        </div> */}

        {
          likeButtonsEnabled2 && <IonFab
            className="bottom-actions"
            vertical="bottom"
            horizontal="center"
            slot="fixed"
          >
            <IonButton
              color="white"
              className="button-custom button-icon button-dislike button-lg margin_right_100px"
              onClick={() => { handleClickWrapper('NOPE') }}
            >
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
            {/* <IonButton
              color="white"
              className="button-custom button-icon button-star"
            >
              <IonIcon slot="icon-only" icon={star} />
            </IonButton> */}
            <IonButton
              color="white"
              className="button-custom button-icon button-like button-lg"
              onClick={() => { handleClickWrapper('LIKE') }}
            >
              <IonIcon slot="icon-only" icon={heartSharp} />
            </IonButton>
          </IonFab>
        }

        <br />
        <br />
        <br />
        <br />

      </IonContent>
    </>
  );
};

Profile.defaultProps = {};

export default Profile;
