import { IonItem, IonIcon, IonNote } from '@ionic/react';
import { refreshCircleOutline, repeatOutline } from 'ionicons/icons';
import React from 'react';

function RefreshServiceWorkerButton() {
  const [showButton, setShowButton] = React.useState(true);

  React.useEffect(() => {
    // Check if the browser supports service workers
    if ('serviceWorker' in navigator) {
      // Register the service worker
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          // Check if an update is available
          if (registration.waiting) {
            setShowButton(true);
          }

          // Listen for updates to the service worker
          registration.addEventListener('updatefound', () => {
            // Update is being installed
            const installingWorker = registration.installing;

            if (!installingWorker) return;

            // Listen for state changes of the new service worker
            installingWorker.addEventListener('statechange', () => {
              if (installingWorker.state === 'installed') {
                // New service worker is installed
                if (navigator.serviceWorker.controller) {
                  // An update is available
                  setShowButton(true);
                }
              }
            });
          });
        });
    }
  }, []);

  function handleClick() {
    // Check if the browser supports service workers
    if ('serviceWorker' in navigator) {
      // Get the registration of the active service worker
      navigator.serviceWorker.getRegistration()
        .then(registration => {
          // Check if an update is available
          if (registration && registration.waiting) {
            // SkipWaiting will activate the new service worker
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
        });
    }
  }

  return showButton ? (
    <IonItem className='green-item' lines="none" onClick={() => { handleClick() }}>
      <IonIcon icon={refreshCircleOutline} slot="start" />
      <IonNote className='editable'>Refresh service worker</IonNote>
      <IonIcon icon={repeatOutline} slot="end" />
    </IonItem>
  ) : null;
}

export default RefreshServiceWorkerButton;
