// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import {
  IonRow, IonCol, IonButton, IonIcon, IonModal
} from '@ionic/react';
import {
  mail,
  mailUnreadOutline,
  star,
  checkmarkDone,
  chevronForward
} from 'ionicons/icons';
import './OrderCard.scss';


import {
  getPriceTypeDisplay,
  updateOrder,
  getMentorLevel
} from '../../apis/courtli/courtli';


//PROFILE
import ProfileDiscover from '../../pages/ProfileDiscover/ProfileDiscover';
import MentorProfile from '../../pages/MentorProfile/MentorProfile';

import dayjs from "dayjs";

type Props = {
  order: any,
  viewer: any,
  history: any,
  mode?: string
}

const OrderCard: React.FC<Props> = ({ order, viewer, history, mode }) => {

  //IMAGE HEIGHT
  const imageRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  //INIT
  useEffect(() => {
    setTimeout(() => {
      setImageHeight(getImageHeight());
    }, 1);
  }, []);

  //HANDLE RESIZE
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setImageHeight(getImageHeight());
  }

  //CALC HEIGHT
  function getImageHeight() {
    var goldenRatio = 0.5;
    let imageRefWidth = imageRef.current.clientWidth;
    return Math.floor(imageRefWidth * goldenRatio);
  }

  async function handleDeliverOrder() {
    console.log('handleDeliverOrder() order', order);
    let res = await updateOrder({ id: order.id, status: "delivered" });
    console.log('handleDeliverOrder() res', res);
  }
  async function handleCancelOrder() {
    console.log('handleCancelOrder() order', order);
    let res = await updateOrder({ id: order.id, status: "cancelled" });
    console.log('handleCancelOrder() res', res);
  }
  async function handleApproveOrder() {
    console.log('handleApproceOrder() order', order);
    let res = await updateOrder({ id: order.id, status: "finished" });
    console.log('handleApproceOrder() res', res);
  }
  async function handleRejectOrder() {
    console.log('handleRejectOrder() order', order);
    let res = await updateOrder({ id: order.id, status: "rejected" });
    console.log('handleRejectOrder() res', res);
  }
  function getOrderStatusDisplay(status: string) {
    if (status === "created") {
      return <>
        <span className="spin margin_right_5px">⌛</span>
        <span>In progress...</span>
      </>;
    } else if (status === "delivered") {
      return "📬 Waiting for approval";
    } else if (status === "cancelled") {
      return "❌ Cancelled";
    } else if (status === "rejected") {
      return "❌ Rejected";
    } else if (status === "refunded") {
      return "👛 Refunded";
    } else if (status === "finished") {
      return "✅ Finished";
    }
    return "Unknown status";
  }
  function handleWriteAReview() {
    console.log('handleWriteAReview() order', order);
    history.push("/order/" + order.id + "/review");
  }
  function handleClickOnOrder() {
    if (mode === "MY_ORDERS") {
      if (viewer.id.toString() === order.mentor_id.toString()) {
        history.push("/chat/" + order.mentee_id);
      } else {
        history.push("/chat/" + order.mentor_id);
      }
    }
  }

  function getDateFormatted(time) {
    const formattedDate = dayjs(time).format("hh:mm ∙ MM/DD/YYYY");
    return formattedDate;
  }

  //OPEN MENTOR PAGE
  function viewMentorProfile() {
    history.push("/mentor/" + order.mentor_id)
  }

  //OPEN SERVICE PAGE
  function openService(id: any) {
    history.push("/service/" + id);
  }


  //PROFILE MODAL
  const [isProfileOpen, setProfileOpen] = useState(false);
  function handleOpenProfile() {
    setProfileOpen(!isProfileOpen);
  }
  function handleToggleProfile() {
    setProfileOpen(!isProfileOpen);
  }



  return (<>


    <div key={order.id} onClick={handleClickOnOrder}>

      <div className="order-card">


        <div className='card-header'>
          <IonIcon icon={mailUnreadOutline} slot="start" />

          {(viewer.id.toString() === order.mentor_id.toString() && mode !== "MY_ORDERS") &&
            <span className='card-title'>You have a new order!</span>
          }

          {
            (viewer.id.toString() === order.mentee_id.toString() && mode !== "MY_ORDERS") &&
            <span className='card-title'>Hooray, you made an order!</span>
          }

          <span className='card-time'>{getDateFormatted(order.created_at)}</span>
        </div>


        <IonRow class='mentor-container' onClick={() => { handleToggleProfile() }}>
          <div className="ctiny_avatar" >
            <img src={order.profile_image_url} />
          </div>
          <IonCol class='mentor-info'>
            <div className='mentor-name'>{order.first_name} <IonIcon icon={checkmarkDone} /></div>
            <div className='mentor-level'>{getMentorLevel(order)} mentor,</div>
            <IonIcon className='mentor-forward-icon' icon={chevronForward} />
          </IonCol>
        </IonRow>


        <img ref={imageRef} className='service-image' src={order?.title_image_url} style={{ height: imageHeight + "px" }} />

        {/* 
      <div className="caption-time">
        {getOrderStatusDisplay(order.status)}
      </div>
      */}


        <div className="card-caption">
          <IonRow>
            <IonCol className="caption-text">
              <div className="caption-title">
                {order.name}
              </div>

              <div className='caption-bottom'>
                <div className="caption-price">
                  $ {order.price}
                </div>
              </div>

            </IonCol>
          </IonRow>
        </div>

        {/* BUTTONS FOR MENTEE */}
        <div className='caption-actions'>

          {
            (viewer.id.toString() === order.mentee_id.toString() && order.status === "created") &&
            <div className='status-buttons progress-status'>
              <div className='status-dot'></div>
              <span className='status-text'>Waiting for the mentor to complete this order...</span>
              <span className='status-button'>Dismiss</span>
            </div>
          }

          {
            (viewer.id.toString() === order.mentee_id.toString() && order.status === "delivered" && mode !== "MY_ORDERS") &&

            <>
              <div className='status-buttons progress-status'>
                <span className='status-text'>Has the mentor completed the order?</span>
              </div>
              <div className='caption-buttons'>
                <div className="cbutton_gray" onClick={handleApproveOrder}>Yes</div>
                <div className="cbutton_red" onClick={handleRejectOrder}>No</div>
              </div>
            </>
          }
          {
            (viewer.id.toString() === order.mentee_id.toString() && order.status === "finished" && order.review_written === 0 && mode !== "MY_ORDERS") &&
            <>
              <div className='status-buttons progress-status'>
                <span className='status-text'>Your order has been completed!</span>
              </div>
              <div className='caption-buttons'>
                <div className="cbutton_gray" onClick={handleWriteAReview}>Write a review</div>
              </div>
            </>
          }



          {/* BUTTONS FOR MENTOR */}

          {
            (viewer.id.toString() === order.mentor_id.toString() && order.status === "created" && mode !== "MY_ORDERS") &&
            <>
              <div className='status-buttons progress-status'>
                <span className='status-text'>Have you completed the order?</span>
              </div>
              <div className='caption-buttons'>
                <div className="accept-button" onClick={handleDeliverOrder}><span>Yes</span></div>
                <div className="cancel-button" onClick={handleCancelOrder}><span>No</span></div>
              </div>
            </>
          }

          {
            (viewer.id.toString() === order.mentor_id.toString() && order.status === "delivered") &&
            <div className='status-buttons finished-status'>
              <div className='status-dot'></div>
              <span className='status-text'>Waiting for the mentee to confirm...</span>
            </div>
          }

          {
            (viewer.id.toString() === order.mentor_id.toString() && order.status === "finished") &&
            <div className='status-buttons finished-status'>
              <div className='status-dot'></div>
              <span className='status-text'>You have completed this order!</span>
            </div>
          }

        </div>

      </div>

    </div>

    <IonModal isOpen={isProfileOpen} swipeToClose transparent overScrollMode="never">
      <ProfileDiscover
        userId={order.mentor_id}
        onClose={handleToggleProfile}
        history={history}
      />
    </IonModal>

  </>



  );
};

OrderCard.defaultProps = {

}

export default OrderCard;
