import React from 'react';
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonText,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAvatar,
  IonRow
} from '@ionic/react';
import {

} from 'ionicons/icons';

import './OrderCheckout.scss';

import { 
  getCards,
  getCardIconUrl
 } from '../../apis/courtli/courtli';

type Props = {
  onClose: () => void,
  service: any,
  history: any,
  executeOrder: () => void
}

const OrderCheckout: React.FC<Props> = ({ onClose, service, history, executeOrder }) => {

  let [ card, setCard ] = useState<any>({
    brand: "NA",
    last4: "NA"
  });

  useEffect(() => {
    fetchData();

  }, []);

  async function fetchData(){

    let cards = await getCards();
    //console.log("OrderCheckout cards", cards);
    if(cards.length > 0){
      setCard(cards[0]);
    }
  }

  return (
    <>
    
      <IonHeader className="header-custom">
          <IonToolbar>
            <IonTitle>Checkout</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={ onClose }>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
      </IonHeader>

      <IonContent forceOverscroll={false} scrollY={false} className="courtli-gold-page bg-light">
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px">🏁</div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray">
                    Almost there!
                </div>
            </IonRow>


        { card.brand == "NA" &&
          
          <IonRow>
              <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                  Please add a payment method and return to order.
              </div>
              <div className="cbutton_green margin_auto margin_top_20px" onClick={ () => { history.push("/cards");onClose(); } }>
                Add payment method
              </div>
          </IonRow>
        }
        { card.brand != "NA" &&
        
          <>
          <IonRow>
              <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                  Please confirm your order details.
              </div>
          </IonRow>
          <IonRow>
              <div className="margin_auto margin_top_20px t4 color_light_gray width_250px">
                <table className="width_100p">
                  <thead>

                  </thead>
                  <tbody>
                    <tr>
                      <td className="vertical_align_top">
                        <b>Service:</b>
                      </td>
                      <td className="text_align_right">
                        { service.name }
                      </td>
                    </tr>
                    <tr>
                      <td className="vertical_align_top">
                        <b>Price:</b>
                      </td>
                      <td className="text_align_right">
                        ${ service.price }
                      </td>
                    </tr>
                    <tr>
                      <td className="vertical_align_top">
                        <b>Payment:</b>
                      </td>
                      <td className="text_align_right">
                        <div className="card_preview">
                          { <img src={getCardIconUrl(card.brand)} className="height_20px" /> }
                          <span>{ card.last4 }</span>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
          </IonRow>
          <IonRow class="big_action_button">
              <div className="flex">
                  <div className="cbutton_red margin_auto pointer cbutton_95p" onClick={ () => { executeOrder();onClose(); } }>Order now!</div>
              </div>
          </IonRow>
          </>
        }

      </IonContent>
    </>
  );
};

OrderCheckout.defaultProps = {

}

export default OrderCheckout;
