import React, { useEffect, useState } from 'react';
import {
  IonPage, IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonContent, IonSearchbar, IonText, IonIcon, IonRow, IonCol, IonButton,
  IonButtons, IonAvatar, IonTitle,
  IonModal, IonList, IonItem,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonRange,
  IonAlert
} from '@ionic/react';
import RandomAvatar from '../../components/RandomAvatar/RandomAvatar';

import './Chats.scss';

//ICONS
import { chevronForward, chevronDown, trashBinOutline, trashOutline } from 'ionicons/icons';


/// COURTLI
import {
  courtli,
  getToken,
  getMessages,
  getInboxes,
  setToken,
  enrichMessageFromToken,
  getMatches,
  getMentors,
  timeAgo,
  getUser,
  getMessagesFromDB,
  checkPathMatch,
  getLoadingIndicator,
  getNoResultsIndicator,
  decodeHtml
} from '../../apis/courtli/courtli';

//SWIPE
import { SlidingItem, resetAnimation } from '../../components/SlidingItem/SlidingItem'

//DATE FORMAT
import dayjs from "dayjs";
import RemoveUserModal from '../../components/RemoveUserModal/RemoveUserModal';


type Props = {
  history: any,
}

const Chats: React.FC<Props> = ({ history }) => {

  /// VARIABLES

  const [user, setUser] = useState<any>({
    profile_image_url: ""
  });
  const [segmentView, setSegmentView] = useState<string>('MATCHES');

  let [searchQuery, setSearchQuery] = useState<any>({});
  let [matches, setMatches] = useState<any>([]);
  let [mentors, setMentors] = useState<any>([]);
  let [matchesToShow, setMatchesToShow] = useState<any>(false);
  let [mentorsToShow, setMentorsToShow] = useState<any>(false);
  let [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
  const [isRemoveUser, setIsRemoveUser] = useState(false);
  const [removeUser, setRemoveUser] = useState({});

  let [intervalId, setIntervalId] = useState<any>(false);


  /// EXECUTION

  useEffect(() => {
    fetchData();
    refreshInbox();
    setTimeout(() => {
      // set up an interval to refresh the API every 5 sec
      let intervalId1 = setInterval(() => {
        // console.log("Chats useEffect() setInterval() 5 sec");
        refreshInbox();
      }, 5 * 1000);
      setIntervalId(intervalId1);
    }, 5000);

  }, []);


  /// FUNCTION DEFINITIONS

  function stopRefresh() {
    //console.log("Chats stopRefresh() intervalId", intervalId);
    clearInterval(intervalId);
  }

  async function fetchData() {

    if (user.current_role === "mentor") {
      /// MENTORS ONLY SEE MATCHES
      setSegmentView("MATCHES");
    }

    let user_new = await getUser({ use_cache: true });
    setUser(user_new);
  }
  const goToChat = (id: string) => {
    history.push('/chat/' + id);
    stopRefresh();
    return true;
  }
  async function getLastMessage(chat: any) {
    let messages = await getMessages({ friend_user_id: chat.id, limit: 1 });
    // console.log("getLastMessageText() messages", messages);

    let message = null;

    if (messages && messages.length > 0) {
      message = messages[messages.length - 1];
    }

    let body = "Send the first message!";
    if (message) {
      message = await enrichMessageFromToken(message);
      body = message.body;

      body = decodeHtml(body);
      /// FIND SPECIAL CASES
      if (body.indexOf("https:") >= 0) {
        if (body.indexOf(".gif") > 0) {
          body = "Sent a GIF";
        } else {
          body = "Sent Media";
        }
      } else if (body.indexOf("<OrderCard") >= 0) {
        body = "Sent an Order";
      }


      /// CUT SIZE
      if (body.length > 30) {
        body = body.substring(0, 30) + "...";
      }
      message.body = body;
    }

    return message || {
      body: "",
      created_at: new Date().getTime()
    };
  }

  async function getLastMessagesForEachChat(new_matches: any) {

    for (let i = 0; i < new_matches.length; i++) {
      let message = await getLastMessage(new_matches[i]);
      // console.log("getLastMessagesForEachChat() message", message, new_matches[i]);
      message.body = decodeHtml(message.body);
      new_matches[i].last_message = message;
    }

    return new_matches;
    // setMatches(new_matches);
    // setMatchesToShow(new_matches);
    // console.log("getLastMessagesForEachChat() chats", chats);
    /// HOW TO RERENDER
  }

  async function refreshInbox() {

    let unseen_messages = await getMessagesFromDB({
      seen_at: 0,
      created_at_min: new Date().getTime() - 1000 * 60 * 60 * 24 * 14
    });
    // console.log("Chats refreshInbox() unseen_messages", unseen_messages);
    // switchChatMode();
    // switchChatMode();
    // console.log("Chats refreshInbox() segmentView", getSegmentView());

    refreshMatches(unseen_messages);
    if (user.current_role === "mentee") {
      refreshMentors(unseen_messages);
    }

    // if(getSegmentView() === "MATCHES"){
    //   refreshMatches();   
    // }else{
    //   refreshMentors();
    // }
  }

  function combineUniqueUsers(users1: any, users2: any) {
    const combinedUsers = [...users1, ...users2];
    const uniqueUsers = combinedUsers.filter(
      (user, index, self) => index === self.findIndex((u) => u.id === user.id)
    );
    return uniqueUsers;
  }
  async function refreshMatches(unseen_messages: any) {

    let matches_new = [
      {
        id: "0",
        first_name: 'Courtli',
        profile_image_url: courtli.design.logo_url,
        current_role: "mentor"
      }
    ];
    let inbox_api = await getInboxes();
    let matches_api = await getMatches();
    matches_api = combineUniqueUsers(inbox_api, matches_api);
    matches_new = matches_new.concat(matches_api);
    /// CHECK IF A MATCH HAS AN UNSEEN MESSAGE
    matches_new = matches_new.map((match: any) => {
      match.new_messages = false;
      match.last_message = {
        body: "Loading...",
        created_at: 0
      };
      match.unseen_messages = unseen_messages.filter((message: any) => message.from.toString() === match.id.toString()).length;
      if (match.unseen_messages > 0) {
        match.new_messages = true;
      }
      return match;
    });

    // let old_matches = matches;
    // console.log("Chats refreshInbox() old_matches", old_matches);
    // if(old_matches.length === 0){
    //   setMatches(matches_new);
    //   setMatchesToShow(matches_new);     
    // }

    let matches_new2 = await getLastMessagesForEachChat(matches_new);
    // console.log("refreshMatches() matches_new2", matches_new2);
    /// SORT BY DATE
    matches_new2.sort((a: any, b: any) => { return b.last_message.created_at - a.last_message.created_at });
    matches_new2.sort((a: any, b: any) => { return (b.id === "0" ? 1 : 0) - (a.id === "0" ? 1 : 0) });
    setMatches(matches_new2);
    setMatchesToShow(matches_new2);
  }
  async function refreshMentors(unseen_messages: any) {
    let mentors_new = [
      {
        id: "0",
        first_name: 'Courtli',
        profile_image_url: courtli.design.logo_url,
        current_role: "mentor"
      }
    ];

    let mentors_api = await getMentors();
    mentors_new = mentors_new.concat(mentors_api);

    /// CHECK IF A MATCH HAS AN UNSEEN MESSAGE
    mentors_new = mentors_new.map((match: any) => {
      match.new_messages = false;
      match.last_message = {
        body: "Loading..."
      };
      match.unseen_messages = unseen_messages.filter((message: any) => message.from.toString() === match.id.toString()).length;
      if (match.unseen_messages > 0) {
        match.new_messages = true;
      }
      return match;
    });

    // if(mentors.length === 0){
    //   setMentors(mentors_new);
    //   setMentorsToShow(mentors_new); 
    // }

    let mentors_new2 = await getLastMessagesForEachChat(mentors_new);
    mentors_new2.sort((a: any, b: any) => { return (b.id === "0" ? 1 : 0) - (a.id === "0" ? 1 : 0) });
    // console.log("refreshInbox() mentors_new2", mentors_new2);
    setMentors(mentors_new2);
    setMentorsToShow(mentors_new2);
  }

  function changeSearchValue(evt: any) {
    let value = evt.target.value;
    console.log("Chats changeSearchValue() value", value);

    if (value === "") {
      setMatchesToShow(matches);
      return;
    }
    let filtered = matches.filter((match: any) => {
      return match.first_name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
    setMatchesToShow(filtered);

  }


  function setRangeValue(label: string, detail: any) {
    // console.log("setRangeValue()", {label: label, detail: detail});
    let sq = searchQuery;
    let value = detail.value;
    if (label === "rating") {
      value.upper = value.upper / 10;
      value.lower = value.lower / 10;
    }

    sq[label] = value;
    setSearchQuery(sq);
  }

  //CHECK ONLINE
  function isOnline(lastConnection: number) {
    let time = new Date().getTime();
    if (time < lastConnection + 1 * 60 * 1000) {
      return true;
    }
    return false;
  }

  //FORMAT DATE
  function getDateFormatted(time: number) {
    const formattedDate = dayjs(time).format("hh:mm ∙ MM.DD.YYYY");
    return formattedDate;
  }

  //DELETE ITEM
  function deleteItem(event: any, user: any) {
    resetAnimation(event);
    //console.log(user);
    setRemoveUser(user);
    setIsRemoveUser(true);
  }

  //console.log(matches);


  return (

    <IonPage className="matches-page">

      <IonHeader className='no-border'>


        <IonToolbar className='toolbar-no-border toolbar-no-safe-area'>
          <IonButtons slot='start'>
            <IonAvatar onClick={() => { history.push("/tabs/profile") }}>
              <img src={user.profile_image_url} alt="" />
            </IonAvatar>
          </IonButtons>
          <IonTitle>Chat</IonTitle>
        </IonToolbar>


        <div className="search-container">
          <IonSearchbar className="search-bar" placeholder={"Search by keywords..."} onIonInput={evt => changeSearchValue(evt)}></IonSearchbar>
          <IonIcon className='search-red-icon' icon={chevronForward} />
        </div>


        <div className="filrers-container">
          <div className='matches-filter'>
            <span>matches & mentors</span>
            <IonIcon icon={chevronDown} />
          </div>
          <div className='matches-filter'>
            <span>latest message</span>
            <IonIcon icon={chevronDown} />
          </div>
        </div>


      </IonHeader>


      <IonContent>

        <div className="safe-area-bottom">

          <div>

            <div>

              <div className="message-list">

                {
                  matchesToShow === false ? getLoadingIndicator() : (matchesToShow.length === 0 ? getNoResultsIndicator() : matchesToShow.map((match: any) => {

                    let chat_avatar = <RandomAvatar customSize={48} src={match.profile_image_url} />;
                    let messageDate = getDateFormatted(match.last_message.created_at);

                    return <SlidingItem
                      right={<div className='delete-red-container' onClick={(event) => { deleteItem(event, match) }}> <IonIcon icon={trashOutline} /> </div>}
                      key={match.id}>

                      <div className='chat-card' onClick={() => { goToChat(match.id) }}>

                        {(match.unseen_messages > 0) && <span className='new-chat-dot'></span>}

                        <IonRow className="ion-align-items-center"  >
                          <IonCol size="auto">
                            {chat_avatar}
                          </IonCol>
                          <IonCol className="message-item-text">
                            <div>
                              <div className="user-type">
                                {(!match.current_role) && <span>CHAT</span>}
                                {(match.current_role === "mentee") && <span >MENTEE</span>}
                                {match.current_role === "mentor" && <span >MENTOR</span>}

                                <span className='dot'>∙</span>
                                {(isOnline(match.last_online_at)) ? <span className='status-online'>ONLINE</span> : <span className='status-offline'>OFFLINE</span>}

                              </div>
                              <div className="user-name">{match.first_name} ∙ {match.age}</div>
                              <div className='message-text'>{match.last_message.body}</div>
                              <div className='message-time'>{messageDate}</div>

                            </div>
                          </IonCol>
                        </IonRow>


                      </div>

                    </SlidingItem>



                  }))

                }

                {/*
                  matchesToShow === false ? getLoadingIndicator() : (matchesToShow.length === 0 ? getNoResultsIndicator() : matchesToShow.map((match: any) => {
                    let chat_avatar = <RandomAvatar customSize={48} src={match.profile_image_url} />;

                    return <div className='chat-card' onClick={() => { goToChat(match.id) }}>

                      {(match.unseen_messages > 0) && <span className='new-chat-dot'></span>}

                      <IonRow className="ion-align-items-center" key={match.id} >
                        <IonCol size="auto">
                          {chat_avatar}
                        </IonCol>
                        <IonCol className="message-item-text">
                          <div>
                            <div className="user-type">
                              {(!match.current_role) && <span>CHAT</span>}
                              {(match.current_role === "mentee") && <span >MENTEE</span>}
                              {match.current_role === "mentor" && <span >MENTOR</span>}

                              <span className='dot'>∙</span>
                              {(isOnline(match.last_online_at)) ? <span className='status-online'>ONLINE</span> : <span className='status-offline'>OFFLINE</span>}

                            </div>
                            <div className="user-name">{match.first_name} ∙ {match.age}</div>
                            <div className='message-text'>{match.last_message.body}</div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>

                  }))
                */}

              </div>
            </div>
          </div>
        </div>
      </IonContent>



      {/* SEARCH MODAL */}
      <IonModal isOpen={isSearchModalOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Filter Results</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => { setIsSearchModalOpen(false); fetchData(); }}>Done</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="settings-page bg-light">

          <br />

          <IonSearchbar placeholder="Search" className="search-bar"
            onIonChange={({ detail }) => { setRangeValue("keywords", detail) }}
          // onIonInput={evt => changeSearchValue(evt)}
          ></IonSearchbar>

          <IonList className="list-custom">
            <IonListHeader>
              <IonLabel>Role</IonLabel>
            </IonListHeader>
            <IonItem>
              <IonSelect placeholder="Choose role"
                multiple={false}
                value={searchQuery.role}
                onIonChange={({ detail }) => { setRangeValue("role", detail) }}
              >
                <IonSelectOption value="mentee">Mentee</IonSelectOption>
                <IonSelectOption value="mentor">Mentor</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonList>

          <IonList className="list-custom">
            <IonListHeader>
              <IonLabel>Age</IonLabel>
            </IonListHeader>
            <IonItem>
              <IonRange
                dualKnobs={true}
                pin={true}
                pinFormatter={(value: number) => `${value}`}
                min={18}
                max={120}
                onIonChange={({ detail }) => { setRangeValue("age", detail) }}
              >
              </IonRange>
            </IonItem>
          </IonList>

          <div className="cbutton_red margin_auto pointer width_90p" onClick={() => { setIsSearchModalOpen(false); fetchData(); }}>Search</div>

        </IonContent>
      </IonModal>


      {/* REMOVE MODAL */}
      {isRemoveUser && <RemoveUserModal setIsRemoveUser={setIsRemoveUser} user={removeUser} />}


    </IonPage >
  );
};

Chats.defaultProps = {

}

export default Chats;
