import React, { useState, useEffect } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  IonButtons
} from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import AddCardForm from '../../components/AddCardForm/AddCardForm';

import { 
    courtli,
    criticalErrorHandling
} from '../../apis/courtli/courtli';


/// STRIPE
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


type Props = {
    onClose:any,
    history: any
}
  

const AddCardModal: React.FC<Props> = ( { onClose, history } ) => {

  const [stripePromise, setStripePromise] = useState<any>(null);

  useEffect(() => {
    loadStripePromise();
  }, []);

  async function loadStripePromise(){
    let stripePromiseNew = await loadStripe(courtli.stripe.key);
    console.log("AddCardModal stripePromiseNew", stripePromiseNew);
    setStripePromise(stripePromiseNew);
  }

  if(!courtli.stripe){
    criticalErrorHandling(history);
  }

  return (
    <>      
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Card</IonTitle>
            <IonButtons slot="end">
              <IonButton color="primary" onClick={ onClose }>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          { stripePromise && 

            <Elements stripe={stripePromise}>
              <AddCardForm onClose={onClose} />
            </Elements>
          }
        </IonContent>
        {/* <IonButton onClick={ handleAddCard }>Add Card</IonButton>
        <IonButton color="danger" onClick={() => onClose()}>Cancel</IonButton> */}
    </>
  );
};

AddCardModal.defaultProps = {

}

export default AddCardModal;
