// @ts-nocheck

import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonAvatar,
  IonIcon,
  IonRow,
  IonCol,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonBadge,
  IonTabButton
} from "@ionic/react";
import {
  checkmarkOutline,
  settingsSharp,
  camera,
  add,
  pencilSharp,
  chevronBack,
  arrowForward,
  timerOutline
} from "ionicons/icons";
// import CourtliGold from "../CourtliGold/CourtliGold";
// import PlusIntro from "../../components/PlusIntro/PlusIntro";
import TulipLoadingBar from "../../components/TulipLoadingBar/TulipLoadingBar";


import ProfilePreview from "../ProfilePreview/ProfilePreview";
import ProfileEditPrivate from "../ProfileEditPrivate/ProfileEditPrivate";
import ProfileEditPublic from "../ProfileEditPublic/ProfileEditPublic";
// import ProfileEditMedia from "../ProfileEdit/ProfileEditMedia";

import "./ProfileEdit.scss";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

/// COURTLI
import {
  courtli,
  getOnboardingNextStep,
  getUser,
  getUserRoleDisplay,
  getUserProfileProgress,
  courtliHealthCheckWithRedirect,
  changeRole,
  resetCache,
  goBack
} from '../../apis/courtli/courtli';

//SLIDE BUTTON
import SlideButton from 'react-slide-button';
import UserActivity from "../UserActivity/UserActivity";

type Props = {
  history: any;
};


const ProfileEdit: React.FC<Props> = ({ history }) => {

  let [balance, setBalance] = useState(0);
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const [isProfileEditOpen, setIsProfileEditOpen] = useState<boolean>(false);
  const [isActivityOpen, setActivityOpen] = useState<boolean>(false);

  // const [isProfileEditMediaOpen, setIsProfileEditMediaOpen] = useState<boolean>(false);
  // const [isCourtliGoldOpen, setIsCourtliGoldOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>(false);
  let [percentage, setPercentage] = useState(0);

  useEffect(() => {

    courtliHealthCheckWithRedirect(history);
    getUserProfile();

    async function getUserProfile() {

      let new_user = await reloadUser();
      setUser(new_user);
      setTimeout(() => {
        // setTargetPercentage(user.profile_progress);
        handleProgressValues(new_user.profile_progress);
      }, 500);
    }


  }, []);


  async function reloadUser() {

    let new_user = await getUser({ use_cache: false });
    let images = new_user.images;

    // console.log("reloadUser() ", new_user);

    /// RETROFIT SO OLD UI CAN USE IT
    // { id: 0, imageUrl: 'assets/img/people/11.jpeg' },
    images = images.map((image: any) => {
      image.imageUrl = image.url;
      return image;
    })
    new_user.images = images;
    // console.log("UserProfile getUserProfile() user", new_user);
    new_user.role_display = getUserRoleDisplay(new_user.current_role);
    new_user.profile_progress = getUserProfileProgress(new_user);
    let next_step = getOnboardingNextStep(new_user);
    // console.log("UserProfile getUserProfile() next_step", next_step);

    // console.log("UserProfile reloadUser() new_user", new_user);

    return new_user;
  }

  const handleViewSettings = () => {
    setIsSettingsOpen(true);
  };

  const handleViewProfile = () => {
    setIsProfileOpen(true);
  };

  const handleViewProfileEdit = () => {
    setIsProfileEditOpen(true);
  };

  const handleViewProfileEditMedia = () => {
    history.push("/wizards/media");
  };

  // const handleViewCourtliGold = () => {
  //   setIsCourtliGoldOpen(true);
  // };

  const clearTheTimeOut = (timer: any) => {
    clearTimeout(timer);
  };

  const handleProgressValues = (targetPercentage: number) => {
    console.log("UserProfile handleProgressValues() targetPercentage", targetPercentage);
    const theinterval = setInterval(() => {
      percentage = percentage + 1;
      // console.log("UserProfile handleProgressValue()", {
      //   percentage: percentage, user_progress_percent: user.progress_percent 
      // });
      if (percentage <= targetPercentage) {
        setPercentage(percentage);
      } else {
        clearTheTimeOut(theinterval);
      }

      // console.log(percentage);
    }, 40);
  };
  function getSwitchRoleText() {
    if (user.current_role === "mentee") {
      return "SWITCH TO MENTOR";
    } else {
      return "SWITCH TO MENTEE";
    }
  }
  async function switchRole() {
    // toggleRoleChangeWizard();

    if (user.current_role === "mentee") {
      let response: any = await changeRole("mentor");
      resetCache();
      let new_user = await reloadUser();
      setUser(new_user);
      // history.push("/under-construction");
    } else {
      let response: any = await changeRole("mentee");
      let new_user = await reloadUser();
      setUser(new_user)
      // history.push("/tabs/discover");
    }

    setResetButton(counter => counter + 1);
  }

  function getProgressBarColor() {
    let cls = "var(--c-green)";
    if (user.current_role === "mentee") {
      cls = "var(--c-red)";
    }
    // console.log("UserProfile getSwithRoleButtonColor() cls", cls);
    return cls;
  }
  function getSwithRoleButtonColor() {
    let cls = "";
    if (user.current_role === "mentee") {
      cls = "danger";
    }
    // console.log("UserProfile getSwithRoleButtonColor() cls", cls);
    return cls;
  }
  function incrementBalance() {
    setBalance((prevBalance) => prevBalance + 1);
  }


  //SLIDE BUTTON
  const [resetButton, setResetButton] = useState(0);
  function capitalizeFirst(str) {
    var capitalizedWord = "";
    if (str != null) {
      capitalizedWord = str.charAt(0).toUpperCase() + str.slice(1);
    }
    return capitalizedWord;
  };


  //ACTIVITY
  const handleViewActivity = () => {
    setActivityOpen(true);
  };


  return (
    <IonPage>
      <IonHeader className="no-border profile-header">
        <IonToolbar className="user-profile-toolbar toolbar-no-border ">

          <IonButtons slot="start">
            <IonButton onClick={() => { goBack(history, { refresh: true }) }} >
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          {/* <IonButtons slot="start">
            <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
          </IonButtons> */}
          <IonTitle className="ion-text-center">{"My " + capitalizeFirst(user.current_role) + " Profile"}</IonTitle>
          <IonButtons slot="end">
            <IonButton></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent force-overscroll="false" className="me-page bg-light">
        <div className="vertical-layout safe-area-bottom">
          <div className="section-upper">
            <div className="me-header" >
              <div className="progress-holder">
                <CircularProgressbarWithChildren
                  value={percentage}
                  text=""
                  circleRatio={0.75}
                  strokeWidth={5}
                  styles={buildStyles({
                    rotation: 1 / 2 + 1 / 8,
                    strokeLinecap: "round",
                    trailColor: "#DFE7F5",
                    pathColor: getProgressBarColor(),
                    textColor: "#042C5C",
                    textSize: "5px",
                  })}
                ></CircularProgressbarWithChildren>
              </div>
              <IonAvatar className="avatar" onClick={handleViewProfile}>
                <img src={user.profile_image_url} alt="" />
                <IonBadge className="flex al-center jc-center" style={{ backgroundColor: getProgressBarColor() }}>
                  {percentage}% LEVEL 1
                </IonBadge>
              </IonAvatar>

              <div className="detail-holder">
                <span className="me-title">{user.first_name}</span>

                {/*
                <span className="icon-verified" style={{ backgroundColor: getProgressBarColor() }}>
                  <IonIcon icon={checkmarkOutline} />
                </span>
                */}

              </div>
              <div className="me-info">
                {user.age} • {user.location_description} •
              </div>
            </div>

            <div className="section-title temporary_options_centered">
              OPTIONS
            </div>

            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="auto">
                <IonRow className="me-button-group">

                  <IonCol className="ion-text-center">
                    <IonButton
                      color="white"
                      className="button-custom button-icon"
                      onClick={handleViewSettings}
                    >
                      <IonIcon icon={settingsSharp} slot="icon-only" />
                    </IonButton>
                    <div className="button-label">SETTINGS</div>
                  </IonCol>

                  <IonCol className="ion-text-center">
                    <IonButton
                      color="white"
                      className="button-custom button-icon"
                      onClick={handleViewProfileEdit}
                    >
                      <IonIcon icon={pencilSharp} slot="icon-only" />
                    </IonButton>
                    <div className="button-label">PUBLIC</div>
                  </IonCol>

                  <IonCol className="ion-text-center">
                    <div className="button-stack">
                      <IonButton
                        color="white"
                        className="button-custom button-icon"
                        onClick={handleViewProfileEditMedia}
                      >
                        <IonIcon icon={camera} slot="icon-only" />
                      </IonButton>
                    </div>
                    <div className="button-label">MEDIA</div>
                  </IonCol>

                  <IonCol className="ion-text-center">
                    <div className="button-stack">
                      <IonButton
                        color="white"
                        className="button-custom button-icon"
                        onClick={handleViewActivity}
                      >
                        <IonIcon icon={timerOutline} slot="icon-only" />
                      </IonButton>
                    </div>
                    <div className="button-label">ACTIVITY</div>
                  </IonCol>

                </IonRow>
              </IonCol>
            </IonRow>



            {user.current_role &&
              <div className="switch-roles-container">
                <div className="change-role-descripion">
                  Slide to switch roles
                </div>

                <div className={user.current_role == 'mentor' ? 'mentor' : ''}>
                  <SlideButton
                    mainText={"You are a " + capitalizeFirst(user.current_role)}
                    caret={<IonIcon icon={arrowForward} />}
                    reset={resetButton}
                    onSlideDone={() => {
                      console.log("Done!");
                      switchRole();
                    }}
                  />
                </div>
              </div>

            }



          </div>


        </div>
      </IonContent>

      <IonModal isOpen={isSettingsOpen}>
        <ProfileEditPrivate user={user} history={history} onClose={() => { setIsSettingsOpen(false); reloadUser(); }} />
      </IonModal>

      <IonModal isOpen={isProfileOpen} swipeToClose>
        <ProfilePreview
          user={user}
          onClose={() => setIsProfileOpen(false)}
          handleClickLike={() => { return false }}
          handleClickNope={() => { return false }}
          likeButtonsEnabled={false}
        />
      </IonModal>

      <IonModal isOpen={isProfileEditOpen}>
        {user && <ProfileEditPublic user={user} history={history} onClose={() => { setIsProfileEditOpen(false); reloadUser(); }} />}
      </IonModal>


      <IonModal isOpen={isActivityOpen} swipeToClose>
        <UserActivity
          user={user}
          onClose={() => setActivityOpen(false)}
        />
      </IonModal>



      {/* <IonModal isOpen={isCourtliGoldOpen} className="custom-modal-small">
        <CourtliGold onClose={() => setIsCourtliGoldOpen(false)} />
      </IonModal> */}

    </IonPage>
  );
};

ProfileEdit.defaultProps = {};

export default ProfileEdit;
