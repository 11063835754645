import React from 'react';
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonText,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAvatar,
  IonRow,
  IonIcon
} from '@ionic/react';
import { close, star } from 'ionicons/icons';

import './Reviews.scss';
import Review from '../../components/Review/Review';

import {
  getServiceReviews,
  getLoadingIndicator,
  getNoResultsIndicator
} from '../../apis/courtli/courtli';

type Props = {
  onClose: () => void,
  service: any,
  history: any
}

const Reviews: React.FC<Props> = ({ onClose, service, history }) => {

  let [reviews, setReviews] = useState<any>(false);

  useEffect(() => {
    fetchData();

  }, []);

  async function fetchData() {
    let reviews_new = await getServiceReviews({
      target_type: "service",
      target_id: service.id
    });

    console.log("Reviews reviews_new", reviews_new);
    setReviews(reviews_new);
  }

  return (
    <>
      <IonHeader className="no-border">
        <IonToolbar className="user-profile-toolbar toolbar-no-border">

          <IonTitle>reviews</IonTitle>

          <IonButtons slot="end">
            <IonButton>
              <div onClick={onClose}>
                <IonIcon icon={close} />
              </div>
            </IonButton>
          </IonButtons>

        </IonToolbar>
        <span className="reviews-title">{service.rating_count} users rated this as <IonIcon className='star-icon' icon={star} />{Math.round(service.rating_stars * 10) / 10}</span>
      </IonHeader >

      <IonContent>
        <div className='reviews-container'>
          {
            reviews === false ? getLoadingIndicator() : (reviews.length === 0 ? getNoResultsIndicator() : reviews.map((review: any) => {
              return <Review key={review.id} review={review} history={history} onClose={onClose} />
            }))
          }

        </div>

        {/* <IonRow class="big_action_button">
            <div className="flex">
                <div className="cbutton_red margin_auto pointer cbutton_95p" onClick={ () => { history.push("/under-construction");onClose(); } }>Order now!</div>
            </div>
        </IonRow> */}

      </IonContent>
    </>
  );
};

Reviews.defaultProps = {

}

export default Reviews;
